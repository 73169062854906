import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSurveyById, updateSurvey } from "services/survey.service";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AboutUsHeader from "components/Headers/AboutUsHeader";
import "../../Styling/App.css";

const SurveyPage = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({
    s_title: "",
    s_questions: [{ question: "", answers: [{ answer: "", weight: "" }] }],
    s_type: "pre_course",
    s_surveyType: "voice_over",
    s_published: false,
  });

  useEffect(() => {
    if (surveyId) {
      fetchSurveyById(surveyId);
    }
  }, [surveyId]);

  const fetchSurveyById = async (id) => {
    try {
      const response = await getSurveyById(id);
      if (response.success) {
        setSurvey(response.data);
      }
    } catch (error) {
      console.error("Error fetching survey:", error);
    }
  };

  const handleTitleChange = (e) => {
    setSurvey({ ...survey, s_title: e.target.value });
  };

  const handleQuestionChange = (index, e) => {
    const newQuestions = [...survey.s_questions];
    newQuestions[index][e.target.name] = e.target.value;
    setSurvey({ ...survey, s_questions: newQuestions });
  };

  const handleAnswerChange = (qIndex, aIndex, e) => {
    const newQuestions = [...survey.s_questions];
    newQuestions[qIndex].answers[aIndex][e.target.name] = e.target.value;
    setSurvey({ ...survey, s_questions: newQuestions });
  };

  const addQuestion = () => {
    setSurvey({
      ...survey,
      s_questions: [
        ...survey.s_questions,
        { question: "", answers: [{ answer: "", weight: "" }] },
      ],
    });
  };

  const addAnswer = (qIndex) => {
    const newQuestions = [...survey.s_questions];
    newQuestions[qIndex].answers.push({ answer: "", weight: "" });
    setSurvey({ ...survey, s_questions: newQuestions });
  };

  const handleSave = async () => {
    try {
      const response = await updateSurvey(surveyId, survey);
      if (response.success) {
        alert("Survey updated successfully!");
        navigate("/admin-home");
      } else {
        alert("Error updating survey. Please try again.");
      }
    } catch (error) {
      console.error("Error updating survey:", error);
      alert("Error updating survey. Please try again.");
    }
  };

  return (
    <>
      <AppNavbar />
      <AboutUsHeader />
      <Container className="mt-4">
        <Card>
          <CardBody>
            <CardTitle tag="h2" className="text-center mb-4">
              Edit Survey
            </CardTitle>
            <Form>
              <FormGroup>
                <Label for="surveyTitle">Survey Title:</Label>
                <Input
                  type="text"
                  id="surveyTitle"
                  value={survey.s_title}
                  onChange={handleTitleChange}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <Label for="surveyType">Survey Type:</Label>
                <Input
                  type="select"
                  id="surveyType"
                  value={survey.s_type}
                  onChange={(e) =>
                    setSurvey({ ...survey, s_type: e.target.value })
                  }
                  className="form-control"
                >
                  <option value="pre_course">Pre Course</option>
                  <option value="during_course">During Course</option>
                  <option value="post_course">Post Course</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="surveyCategory">Survey Category:</Label>
                <Input
                  type="select"
                  id="surveyCategory"
                  value={survey.s_surveyType}
                  onChange={(e) =>
                    setSurvey({ ...survey, s_surveyType: e.target.value })
                  }
                  className="form-control"
                >
                  <option value="voice_over">Voice Over</option>
                  <option value="public_speaking">Public Speaking</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="published">Published:</Label>
                <Input
                  type="select"
                  id="published"
                  value={survey.s_published}
                  onChange={(e) =>
                    setSurvey({
                      ...survey,
                      s_published: e.target.value === "true",
                    })
                  }
                  className="form-control"
                >
                  <option value="false">False</option>
                  <option value="true">True</option>
                </Input>
              </FormGroup>
              {survey.s_questions.map((q, qIndex) => (
                <div key={qIndex} className="form-group mb-4">
                  <Label>Question {qIndex + 1}:</Label>
                  <Input
                    type="text"
                    name="question"
                    value={q.question}
                    onChange={(e) => handleQuestionChange(qIndex, e)}
                    className="form-control mb-2"
                  />
                  {q.answers.map((a, aIndex) => (
                    <Row key={aIndex} className="align-items-center mb-2">
                      <Col md={6}>
                        <Label>Answer {aIndex + 1}:</Label>
                        <Input
                          type="text"
                          name="answer"
                          value={a.answer}
                          onChange={(e) =>
                            handleAnswerChange(qIndex, aIndex, e)
                          }
                          className="form-control"
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Weight:</Label>
                        <Input
                          type="text"
                          name="weight"
                          value={a.weight}
                          onChange={(e) =>
                            handleAnswerChange(qIndex, aIndex, e)
                          }
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  ))}
                  <Button
                    type="button"
                    onClick={() => addAnswer(qIndex)}
                    className="learn-more-btn"
                  >
                    Add Answer
                  </Button>
                </div>
              ))}
              <div className="form-buttons d-flex justify-content-between">
                <Button
                  type="button"
                  onClick={addQuestion}
                  className="learn-more-btn"
                >
                  Add Question
                </Button>
                <Button
                  type="button"
                  onClick={handleSave}
                  className="learn-more-btn"
                >
                  Save Survey
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
      <FooterBlack />
    </>
  );
};

export default SurveyPage;
