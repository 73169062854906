import React, { useEffect, useState } from "react";

import { getProducts } from "../services/product.service";
import { getAuth, signOut } from "firebase/auth";
import AppNavbar from "components/Navbars/AppNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import styles from "../assets/scss/book-now.scss";
import { initializePayment } from "../services/booking.service";
import Banner from "components/banner";
import CardComponent from "components/Video/CardComponent";
const BookNow = () => {
  const [products, setProducts] = useState([]);
  const backgroundImage = "assets/img/sections/mic-background.jpg";
  const auth = getAuth();
  const cards = [];

  useEffect(() => {
    // signOut(auth).then(() => {

    // });
    handleGetProducts();
  }, []);
  const handleGetProducts = async () => {
    const response = await getProducts();

    if (response.success) {
      let data = [...response.data];
      data = data.sort((a, b) => a.p_order - b.p_order);
      setProducts([...data]);
    }
  };

  const handleBooking = async (p_id) => {
    const bookingData = {
      packageId: p_id,
      userId: auth.currentUser.uid,
    };

    const response = await initializePayment(bookingData);

    if (response.success) {
      window.open(response.data.url, "_self");
    } else {
    }
  };
  return (
    <div className="wrapper">
      <AppNavbar />
      <Banner
        title="Book a course"
        description=""
        showLogo={true}
        logoSrc="assets/img/ElevateAfrica_White-2.png"
        cards={cards}
        cardsAlignment="center"
      />
      <div className="section text-center book-section">
        <Container>
          <Row className="coloured-cards d-flex justify-content-center ">
            {products &&
              products &&
              products.map((item, index) => (
                <Col md="4" key={index}>
                  <CardComponent
                    videoSrc={item.p_video_url}
                    videoWidth={"640px"}
                    videoHeight={"320px"}
                    price={"R " + item.p_price}
                    name={item.p_title}
                    onBookPressed={() => handleBooking(item.p_id)}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BookNow;
