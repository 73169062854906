import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.png";
import phone from "../../assets/img/phone.png";

function FooterBlack() {
  return (
    <footer
      className="footer"
      style={{ backgroundColor: "#f4f5f7", padding: "10px 0" }}
    >
      <Container>
        <Row className="footer-content justify-content-between align-items-center">
          {/* Company Name */}
          <Col
            xs={12}
            md="auto"
            className="text-center text-md-start company-name"
          >
            <span
              style={{
                color: "black",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontSize: "clamp(1rem, 2vw, 1.2rem)",
              }}
            >
              Elevate Africa Academy
            </span>
          </Col>

          {/* Social Links */}
          <Col xs={12} md="auto" className="text-center social-links">
            <nav className="footer-nav">
              <ul
                style={{
                  display: "flex",
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <li>
                  <Button
                    color="link"
                    href="https://www.instagram.com/elevate.africa/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ padding: 0 }}
                  >
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Button>
                </li>
                <li>
                  <Button
                    color="link"
                    href="https://www.linkedin.com/company/elevate-africa-academy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ padding: 0 }}
                  >
                    <img
                      src={linkedin}
                      alt="LinkedIn"
                      style={{ width: "26px", height: "26px" }}
                    />
                  </Button>
                </li>
                <li>
                  <Button
                    color="link"
                    href="tel:+27835385638"
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={phone}
                      alt="Phone"
                      style={{
                        width: "26px",
                        height: "26px",
                        marginRight: "5px",
                      }}
                    />
                    <span
                      style={{
                        color: "black",
                        fontSize: "0.9em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      +27 83 538 5638
                    </span>
                  </Button>
                </li>
              </ul>
            </nav>
          </Col>

          {/* Links and Copyright */}
          <Col
            xs={12}
            md="auto"
            className="text-center text-md-end legal-links"
          >
            <nav className="footer-nav">
              <ul
                style={{
                  display: "flex",
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <li>
                  <Link
                    to="/privacy-policy"
                    style={{
                      color: "black",
                      fontSize: "0.9em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    style={{
                      color: "black",
                      fontSize: "0.9em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    © {new Date().getFullYear()}
                  </span>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>

      <style>
        {`
          .footer-content {
            display: flex;
            flex-wrap: nowrap;
          }

          .footer-nav a {
            transition: opacity 0.2s ease;
          }

          .footer-nav a:hover {
            opacity: 0.7;
            text-decoration: none;
          }

          .footer-nav button {
            transition: opacity 0.2s ease;
          }

          .footer-nav button:hover {
            opacity: 0.7;
          }

          @media (max-width: 768px) {
            .footer {
              padding: 20px 0;
            }

            .footer-content {
              flex-direction: column;
              gap: 20px;
            }

            .company-name {
              margin-bottom: 10px;
            }

            .social-links {
              margin-bottom: 10px;
            }

            .legal-links ul {
              flex-wrap: wrap;
              gap: 15px;
            }
          }
        `}
      </style>
    </footer>
  );
}

export default FooterBlack;
