import React from "react";

const UserReport = ({ report }) => {
  return (
    <div style={{ textAlign: "center", maxWidth: "600px", margin: "0 auto" }}>
      <h2>Progress Report</h2>
      <p>Total Surveys: {report.totalQuizzes}</p>
      <p>Completed Surveys: {report.completedQuizzes}</p>
      <p>Progress: {report.progressPercentage}%</p>

      <h3 style={{ color: "black", marginBottom: "0px" }}>Survey Details</h3>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {report.reportDetails.map((quiz, index) => (
          <li key={index} style={{ marginBottom: "20px" }}>
            <h4>{quiz.title}</h4>
            <p>
              Score: {quiz.totalScore}/{quiz.surveyTotal}
            </p>
            <p>Feedback: {quiz.feedback}</p>
            <p>Completed On: {quiz.completedOn}</p>
          </li>
        ))}
      </ul>

      <h3>Areas for Improvement</h3>
      {report.areasForImprovement.length > 0 ? (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {report.areasForImprovement.map((quiz, index) => (
            <li key={index} style={{ marginBottom: "20px" }}>
              <h4>{quiz.title}</h4>
              <p>
                Score: {quiz.totalScore}/{quiz.surveyTotal}
              </p>
              <p>Feedback: {quiz.feedback}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No areas for improvement identified.</p>
      )}
    </div>
  );
};

export default UserReport;
