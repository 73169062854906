import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import "../../Styling/App.css";

const QuizButton = () => {
  return (
    <div className="quiz-button-container text-center my-5">
      <h3 style={{ fontWeight: "bold", marginBottom: "20px", color: "#000080", }}>
        EVALUATE YOUR PUBLIC SPEAKING SKILLS
      </h3>
      <p className="mb-4" style={{ fontSize: "18px" }}>
        Discover your current speaking level and get personalized recommendations
      </p>
      <Link to="/speaking-assessment">
        <Button
          className="quiz-cta-button"
          size="lg"
          style={{
            backgroundColor: "#000080",
            borderColor: "#000080",
            padding: "12px 30px",
            fontSize: "18px",
            fontWeight: "600",
            borderRadius: "8px",
            boxShadow: "0 4px 14px rgba(0, 0, 128, 0.25)",
            transition: "all 0.3s ease"
          }}
        >
          <i className="fa fa-check-circle mr-2"></i>
          Take The Assessment Quiz
        </Button>
      </Link>
      <div className="mt-3">
        <small className="text-muted">
          Quick 5-minute evaluation • Learn your strengths and areas for growth
        </small>
      </div>
    </div>
  );
};

export default QuizButton;