import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";

import LandingPage from "views/LandingPage.js";
import LoginPage from "auth/login";
import RegisterPage from "auth/register";
import ProtectedRoute from "./guard/ProtectedRoute";
import AboutUs from "views/AboutUs";
import PublicSpeaking from "views/PublicSpeaking";
import ContactUs from "views/ContactForm";
import JciToyp from "views/AboutUsSections/JciToyp";
import YouthOwnedBrand from "views/AboutUsSections/YouthOwnedBrand";
import PublicSpeakingChampionships from "views/AboutUsSections/PublicSpeakingChampionships";
import BookNow from "views/BookNow";
import PrivacyPolicy from "views/PrivacyPolicy";
import TigerbrandsPartnership from "views/Partnerships/TigerbrandsPartnership";
import BrandsSaPartnership from "views/Partnerships/BrandsSaPartnership";
import TermsAndConditions from "views/TermsAndConditions";
import VoiceOver from "views/VoiceOver";
import Alumni from "views/Alumni";
import CreateSurvey from "views/AdminViews/CreateSurvey";
import AdminHome from "./views/AdminViews/AdminHome";

import SurveyPage from "views/AdminViews/SurveyPage";
import Forms from "views/Forms";
import EndScreen from "components/Form/EndScreen";
import { GameStateProvider } from "assets/Helpers/gameContexts";
import GroupManagement from "views/AdminViews/GroupManagement";
import SurveyManagement from "views/AdminViews/SurveyManagement";
import CreateGroup from "views/AdminViews/CreateGroup";
import AssignQuizzes from "views/AdminViews/AssignSurveys";
import EditGroup from "views/AdminViews/EditGroup";
import SendCerts from "views/AdminViews/SendCert";
import PaymentSuccessPage from "views/Payment Redirects/Success";
import PaymentErrorPage from "views/Payment Redirects/Error";
import CancelPage from "views/Payment Redirects/Cancel";
import SpeakingAssessmentQuiz from "views/SpeakingAssessmentQuiz";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GameStateProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<LandingPage />} />
        <Route
          path="/book-now"
          element={
            <ProtectedRoute redirectUrl="/book-now">
              <BookNow />
            </ProtectedRoute>
          }
        />
        <Route
          path="/form"
          element={
            <ProtectedRoute redirectUrl="form">
              <Forms />
            </ProtectedRoute>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/jci-toyp" element={<JciToyp />} />
        <Route path="/youth-owned-brands" element={<YouthOwnedBrand />} />
        <Route
          path="/2022-up-public-speaking-championships/"
          element={<PublicSpeakingChampionships />}
        />
        <Route path="/public-speaking" element={<PublicSpeaking />} />
        <Route path="/voice-over" element={<VoiceOver />} />
        <Route path="/speaking-assessment" element={<SpeakingAssessmentQuiz />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login-page" element={<LoginPage />} />
        <Route path="/register-page" element={<RegisterPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Tiger-Brands" element={<TigerbrandsPartnership />} />
        <Route path="/Brands-Southafrica" element={<BrandsSaPartnership />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/end-screen" element={<EndScreen />} />
        <Route path="/alumni" element={<Alumni />} />
        <Route path="/edit-group/:groupId" element={<EditGroup />} />
        <Route path="/send-certificates/:groupId" element={<SendCerts />} />
        <Route path="/Success" element={<PaymentSuccessPage />} />
        <Route path="/Error" element={<PaymentErrorPage />} />
        <Route path="/Cancel" element={<CancelPage />} />

        {/* Individual admin routes wrapped with ProtectedRoute */}
        <Route
          path="/create-survey"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/create-survey">
              <CreateSurvey />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-home"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/admin-home">
              <AdminHome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/survey-page/:surveyId"
          element={
            <ProtectedRoute
              adminRoute={true}
              redirectUrl="/survey-page/:surveyId"
            >
              <SurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/survey-management"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/survey-management">
              <SurveyManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/group-management"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/group-management">
              <GroupManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-group"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/create-group">
              <CreateGroup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-quizzes"
          element={
            <ProtectedRoute adminRoute={true} redirectUrl="/assign-quizzes">
              <AssignQuizzes />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  </GameStateProvider>
);
