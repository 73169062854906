import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function ContactUsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/mic-background.jpg") + ")",
        }}
      >
        <div className="filter " />
        <div className="content-center">
          <Container>
            <h1>Get in touch with us</h1>
            <h3>
              For inquiries about our services or any questions, please fill the
              form below
            </h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUsHeader;
