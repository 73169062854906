import React, { useState, useEffect } from "react";
import FooterBlack from "components/Footers/FooterBlack";
import ContactUsHeader from "components/Headers/ContactUsHeader";
import AppNavbar from "components/Navbars/AppNavbar";
import sendEmail from "Controllers/sendEmail";
import Accordion from "components/Accordion/Accordion";
import { getFaq } from "../service/faq";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

// core components

function ContactUs() {
  const [faqData, setfaqData] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
    phoneNumber: "",
    inquiryType: {
      publicSpeakingPrices: false,
      publicSpeakingTrainingDates: false,
      voiceOverActingPrices: false,
      voiceOverActingTrainingDates: false,
      other: false,
    },
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        inquiryType: {
          ...prevState.inquiryType,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData).then((response) => {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phoneNumber: "",
        inquiryType: {
          publicSpeakingPrices: false,
          publicSpeakingTrainingDates: false,
          voiceOverActingPrices: false,
          voiceOverActingTrainingDates: false,
          other: false,
        },
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFaq();
      const FaqArrayData = data.data;
      setfaqData(FaqArrayData);
    };

    fetchData();
  }, []);
  return (
    <>
      <AppNavbar />
      <ContactUsHeader />

      <Container style={{ marginTop: "30px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <Card className="card-contact no-transition">
              <CardTitle
                className="ml-auto mr-auto text-center"
                tag="h2"
                style={{ fontWeight: "bold", margin: "20px 0", color: "black" }}
              >
                Fill in the form
              </CardTitle>
              <Row>
                <Col md="12">
                  <Form
                    id="contact-form"
                    method="post"
                    role="form"
                    onSubmit={handleSubmit}
                  >
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup className="label-floating">
                            <label className="control-label">First name</label>
                            <Input
                              name="firstName"
                              placeholder="First Name"
                              type="text"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="label-floating">
                            <label className="control-label">Last name</label>
                            <Input
                              name="lastName"
                              placeholder="Last Name"
                              type="text"
                              value={formData.lastName}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup className="label-floating">
                        <label className="control-label">Phone Number</label>
                        <Input
                          name="phoneNumber"
                          placeholder="Phone Number"
                          type="tel"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>

                      <FormGroup className="label-floating">
                        <label className="control-label">Email address</label>
                        <Input
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          type="email"
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>

                      <label>What would you like to Enquire About?</label>

                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultValue=""
                            type="checkbox"
                            name="publicSpeakingPrices"
                            checked={formData.inquiryType.publicSpeakingPrices}
                            onChange={handleChange}
                          />
                          Public Speaking Pricing{" "}
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>

                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultValue=""
                            type="checkbox"
                            name="publicSpeakingTrainingDates"
                            checked={
                              formData.inquiryType.publicSpeakingTrainingDates
                            }
                            onChange={handleChange}
                          />
                          Public Speaking Training Dates{" "}
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultValue=""
                            type="checkbox"
                            name="voiceOverActingPrices"
                            checked={formData.inquiryType.voiceOverActingPrices}
                            onChange={handleChange}
                          />
                          Voice Over Acting Pricing{" "}
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultValue=""
                            type="checkbox"
                            name="voiceOverTrainingDates"
                            checked={
                              formData.inquiryType.voiceOverActingTrainingDates
                            }
                            onChange={handleChange}
                          />
                          Voice Over Acting Training Dates{" "}
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultValue=""
                            type="checkbox"
                            name="other"
                            checked={formData.inquiryType.other}
                            onChange={handleChange}
                          />
                          Other <span className="form-check-sign" />
                        </Label>
                      </FormGroup>

                      <FormGroup className="label-floating">
                        <label className="control-label">Your message</label>
                        <Input
                          id="message"
                          name="message"
                          placeholder="Message"
                          type="textarea"
                          rows="6"
                          onChange={handleChange}
                          value={formData.message}
                        />
                      </FormGroup>
                      <Row>
                        <Col md="12">
                          <Button
                            color="info"
                            className="learn-more-btn"
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <div className="faq">
              <h4 style={{ textAlign: "center" }}>
                Frequently Asked Questions
              </h4>
              <br />
              <div id="acordeon">
                <Accordion data={faqData} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </>
  );
}

export default ContactUs;
