import React from "react";
import { Row, Col, Button } from "reactstrap";

const SponsorshipSection = () => (
  <Row style={{ marginBottom: "30px" }}>
    <Col md="6">
      <strong>
        <h2 className="title">2022 Mrs Universe Africa Sponsor</h2>
      </strong>
      <p>
        African Beauty International Organization is a home for beauty with a
        purpose, a mission and a cause. Elevate Africa academy sponsors the
        contestants with the oratory skills they need to stand out and take home
        this prestigious crown.
      </p>
      <Button
        color="primary"
        href="https://www.instagram.com/mrsuniverseafrica/"
        target="_blank"
        style={{ margin: "10px" }}
        className="learn-more-btn"
      >
        Read More
      </Button>
    </Col>
    <Col md="6">
      <img
        alt="..."
        className="img"
        src={require("assets/img/demo/missuniverse.png")}
        style={{
          borderRadius: "10px",
        }}
      />
    </Col>
  </Row>
);

export default SponsorshipSection;
