import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../services/user.service";
import { getGroupById } from "../../service/groups";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import emailjs from "emailjs-com";
import { addCertificate } from "../../service/certificate";
import { storage } from "../../firebase-config/firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Spinner,
} from "reactstrap";
import * as fontkit from "fontkit";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";
import voiceOverCertificatePath from "../../assets/Certificates/VoiceOver.pdf";
import publicSpeakingCertificatePath from "../../assets/Certificates/publicSpeaking.pdf";
import fontFile from "../../assets/fonts/GreatVibes-Regular.ttf";

const SendCerts = () => {
  const { groupId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [g_category, setG_category] = useState("");

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const response = await getGroupById(groupId);
        if (response.success) {
          setGroupName(response.group.groupName);
          setG_category(response.group.g_category);
          console.log(g_category);
          const members = await Promise.all(
            response.group.members.map(async (userId) => {
              const userResponse = await getUserById(userId);
              return userResponse.data.data;
            })
          );
          setSelectedUsers(members);
        }
      } catch (error) {
        console.error("Error fetching group details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRemoveUserFromGroup = (userId) => {
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((user) => user.uid !== userId)
    );
  };

  const filteredUsers = selectedUsers.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // const sendCertificates = async () => {
  //   console.log("Group category:", g_category);

  //   const certificateToUse =
  //     g_category === "voice_over"
  //       ? voiceOverCertificatePath
  //       : publicSpeakingCertificatePath;

  //   console.log("Certificate to use:", certificateToUse);
  //   for (const user of selectedUsers) {
  //     console.log(`Processing certificate for user: ${user.fullName}`);
  //     try {
  //       const existingPdfBytes = await fetch(certificateToUse).then((res) => {
  //         if (!res.ok) {
  //           throw new Error(`Failed to fetch PDF: ${res.statusText}`);
  //         }
  //         return res.arrayBuffer();
  //       });

  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //       console.log("PDF loaded successfully");
  //       const fontBytes = await fetch(fontFile).then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const customFont = await pdfDoc.embedFont(fontBytes);

  //       // Optionally remove any form fields before drawing text
  //       try {
  //         const form = pdfDoc.getForm();
  //         const fields = form.getFields();
  //         fields.forEach((field) => form.removeField(field));
  //         console.log("Form fields removed successfully");
  //       } catch (error) {
  //         console.error("Error removing form fields:", error);
  //       }

  //       try {
  //         const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  //         console.log("Font embedded successfully");

  //         const pages = pdfDoc.getPages();
  //         const firstPage = pages[0];
  //         const fontSize = 30;
  //         const textColor = rgb(0 / 255, 51 / 255, 102 / 255);
  //         const { width, height } = firstPage.getSize();
  //         const textWidth = font.widthOfTextAtSize(user.fullName, fontSize);
  //         const textHeight = fontSize;
  //         const x = (width - textWidth) / 2;
  //         const y = height / 2 + textHeight / 4;

  //         firstPage.drawText(user.fullName, {
  //           x,
  //           y,
  //           size: fontSize,
  //           color: textColor,
  //           font: customFont,
  //         });
  //         console.log("Text drawn on the certificate successfully");
  //       } catch (error) {
  //         console.error("Error embedding font or drawing text:", error);
  //       }

  //       try {
  //         console.log("Attempting to save PDF...");
  //         const flattenedPdfBytes = await pdfDoc.save({
  //           useObjectStreams: false,
  //         });
  //         console.log("PDF saved successfully");

  //         console.log("Converting PDF to Blob...");
  //         const pdfBlob = new Blob([flattenedPdfBytes], {
  //           type: "application/pdf",
  //         });
  //         console.log("PDF converted to Blob successfully");

  //         console.log("Attempting to upload PDF to Firebase storage...");
  //         const storageRef = ref(
  //           storage,
  //           `certificates/${user.uid}/${user.fullName}.pdf`
  //         );
  //         await uploadBytes(storageRef, pdfBlob);
  //         console.log("PDF uploaded to Firebase storage successfully");

  //         console.log("Attempting to retrieve download URL...");
  //         const downloadURL = await getDownloadURL(storageRef);
  //         console.log("Download URL retrieved successfully:", downloadURL);

  //         // Shorten the URL using Bitly
  //         const shortUrl = await shortenUrl(downloadURL);
  //         console.log("Shortened URL:", shortUrl);

  //         const certificateId = `${user.uid}_${new Date().getTime()}`;
  //         const certificateData = {
  //           userId: user.uid,
  //           certificateId: certificateId,
  //           certificateName: "Certificate of Completion",
  //           issuedDate: new Date().toISOString(),
  //           certificateUrl: shortUrl,
  //         };
  //         const response = await addCertificate(certificateData);

  //         if (!response.success) {
  //           console.error("Error storing certificate:", response.message);
  //           continue;
  //         }
  //         console.log("Certificate stored in the database:", response.message);

  //         const templateParams = {
  //           to_name: user.fullName,
  //           from_name: "Your Organization",
  //           message: "Congratulations! Please find your certificate attached.",
  //           user_email: user.email,
  //           cert_downloadURL: shortUrl,
  //         };

  //         const emailResponse = await emailjs.send(
  //           "service_msehnp9",
  //           "template_cjoe53a",
  //           templateParams,
  //           "L42feFIsJbbDJJ427"
  //         );
  //         console.log(
  //           "Email sent successfully:",
  //           emailResponse.status,
  //           emailResponse.text
  //         );
  //       } catch (error) {
  //         console.error(
  //           `Error in PDF processing or email sending for ${user.fullName}:`,
  //           error
  //         );
  //       }
  //     } catch (error) {
  //       console.error(`Error processing ${user.fullName}:`, error);
  //     }
  //   }
  // };
  PDFDocument.prototype.registerFontkit(fontkit);

  const sendCertificates = async () => {
    console.log("Group category:", g_category);

    const certificateToUse =
      g_category === "voice_over"
        ? voiceOverCertificatePath
        : publicSpeakingCertificatePath;

    console.log("Certificate to use:", certificateToUse);

    for (const user of selectedUsers) {
      console.log(`Processing certificate for user: ${user.fullName}`);
      try {
        // Fetch the certificate template PDF
        console.log("Fetching the PDF template...");
        const existingPdfBytes = await fetch(certificateToUse).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch PDF: ${res.statusText}`);
          }
          return res.arrayBuffer();
        });

        console.log("Loading the PDF document...");
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        console.log("PDF loaded successfully");

        // Register fontkit with the document (IMPORTANT)
        pdfDoc.registerFontkit(fontkit);

        // Load and embed the custom font
        console.log("Fetching the font file...");
        const fontBytes = await fetch(fontFile).then((res) =>
          res.arrayBuffer()
        );
        console.log("Embedding the custom font...");
        const customFont = await pdfDoc.embedFont(fontBytes);
        console.log("Custom font embedded successfully");

        // Optionally remove form fields
        try {
          console.log("Checking and removing any form fields...");
          const form = pdfDoc.getForm();
          const fields = form.getFields();
          fields.forEach((field) => form.removeField(field));
          console.log("Form fields removed successfully");
        } catch (error) {
          console.error("Error removing form fields:", error);
        }

        try {
          // Draw the user's name on the certificate
          console.log("Drawing text on the certificate...");
          const pages = pdfDoc.getPages();
          const firstPage = pages[0];
          const fontSize = 60;
          const textColor = rgb(0 / 255, 51 / 255, 102 / 255);
          const { width, height } = firstPage.getSize();
          const textWidth = customFont.widthOfTextAtSize(
            user.fullName,
            fontSize
          );
          const textHeight = fontSize;
          const x = (width - textWidth) / 2;
          const y = height / 2 + textHeight / 4;

          firstPage.drawText(user.fullName, {
            x,
            y,
            size: fontSize,
            color: textColor,
            font: customFont,
          });
          console.log("Text drawn on the certificate successfully");
        } catch (error) {
          console.error("Error embedding font or drawing text:", error);
        }

        try {
          // Save the modified PDF
          console.log("Attempting to save the modified PDF...");
          const flattenedPdfBytes = await pdfDoc.save({
            useObjectStreams: false,
          });
          console.log("PDF saved successfully");

          // Convert the PDF to a Blob for uploading
          console.log("Converting the PDF to a Blob...");
          const pdfBlob = new Blob([flattenedPdfBytes], {
            type: "application/pdf",
          });
          console.log("PDF converted to Blob successfully");

          // Upload the Blob to Firebase Storage
          console.log("Uploading the PDF Blob to Firebase storage...");
          const storageRef = ref(
            storage,
            `certificates/${user.uid}/${user.fullName}.pdf`
          );
          await uploadBytes(storageRef, pdfBlob);
          console.log("PDF uploaded to Firebase storage successfully");

          // Retrieve the download URL for the uploaded PDF
          console.log("Retrieving the download URL from Firebase storage...");
          const downloadURL = await getDownloadURL(storageRef);
          console.log("Download URL retrieved successfully:", downloadURL);

          // Shorten the download URL using Bitly (optional)
          console.log("Shortening the download URL using Bitly...");
          const shortUrl = await shortenUrl(downloadURL);
          console.log("Shortened URL:", shortUrl);

          // Store certificate information in the database
          console.log("Storing certificate data in the database...");
          const certificateId = `${user.uid}_${new Date().getTime()}`;
          const certificateData = {
            userId: user.uid,
            certificateId: certificateId,
            certificateName: "Certificate of Completion",
            issuedDate: new Date().toISOString(),
            certificateUrl: shortUrl,
          };
          const response = await addCertificate(certificateData);

          if (!response.success) {
            console.error("Error storing certificate:", response.message);
            continue;
          }
          console.log(
            "Certificate stored in the database successfully:",
            response.message
          );

          // Send the certificate via email
          console.log("Sending the certificate via email...");
          const templateParams = {
            to_name: user.fullName,
            from_name: "Your Organization",
            message: "Congratulations! Please find your certificate attached.",
            user_email: user.email,
            cert_downloadURL: shortUrl,
          };

          const emailResponse = await emailjs.send(
            "service_msehnp9",
            "template_cjoe53a",
            templateParams,
            "L42feFIsJbbDJJ427"
          );
          console.log(
            "Email sent successfully:",
            emailResponse.status,
            emailResponse.text
          );
        } catch (error) {
          console.error(
            `Error in PDF processing or email sending for ${user.fullName}:`,
            error
          );
        }
      } catch (error) {
        console.error(`Error processing ${user.fullName}:`, error);
      }
    }
  };

  const shortenUrl = async (longUrl) => {
    try {
      const response = await axios.post(
        "https://api-ssl.bitly.com/v4/shorten",
        { long_url: longUrl },
        {
          headers: {
            Authorization: `50db5011b7671b524bf24d2090335d9ae43f615b`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.link;
    } catch (error) {
      console.error("Error shortening URL:", error);
      return longUrl;
    }
  };
  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Send Certificates</h2>
        <Form>
          <FormGroup>
            <Label
              style={{ margin: "30px", marginLeft: "0px" }}
              for="groupName"
            >
              Group Name
            </Label>
            <div>{groupName}</div>
          </FormGroup>
          <FormGroup>
            <Label for="memberIds">Members</Label>
            <InputGroup
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                type="text"
                placeholder="Search members..."
                value={searchQuery}
                onChange={handleSearch}
                style={{ marginRight: "10px" }}
              />
            </InputGroup>
            <ListGroup>
              {loading ? (
                <Spinner color="primary" />
              ) : filteredUsers.length === 0 ? (
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                  No members found
                </div>
              ) : (
                filteredUsers.map((user) => (
                  <ListGroupItem key={user.uid}>
                    {user.fullName || user.email}
                    <Button
                      color="danger"
                      size="sm"
                      style={{ float: "right" }}
                      onClick={() => handleRemoveUserFromGroup(user.uid)}
                    >
                      Remove
                    </Button>
                  </ListGroupItem>
                ))
              )}
            </ListGroup>
          </FormGroup>
          <Button color="success" onClick={sendCertificates} className="mt-4">
            Send Certificates
          </Button>
        </Form>
      </div>
      <FooterBlack />
    </>
  );
};

export default SendCerts;
