import React, { useContext, useState, useEffect } from "react";
import { GameStateContext } from "../../assets/Helpers/gameContexts";
import { Card, CardBody, Button, Container, Row, Col } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import CleanHeader from "components/Headers/CleanHeader";
import FooterBlack from "components/Footers/FooterBlack";
import "../../Styling/Form.css";
import { submitSurvey } from "service/surveySubmission";
import { getAuth } from "firebase/auth";
import { getSurveyById } from "services/survey.service";
const auth = getAuth();

function Quiz({ id }) {
  const { setGameState, setScore, userId } = useContext(GameStateContext);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [optionChosen, setOptionChosen] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [scoreArray, setScoreArray] = useState([]);
  const [user, setUser] = useState(null);
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    const getSurvey = async () => {
      try {
        const surveyID = await getSurveyById(id);
        const survey = surveyID.data;
        setSurvey(survey);
        setQuestions(survey.s_questions || []);
      } catch (error) {
        console.error("Error fetching survey:", error);
      }
    };

    if (id) {
      getSurvey();
    }
  }, [id]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (userAnswers[currentQuestion]) {
      setOptionChosen(userAnswers[currentQuestion].selectedOption);
    } else {
      setOptionChosen(null);
    }
  }, [currentQuestion, userAnswers]);

  const chooseOption = (answer) => {
    setOptionChosen(answer);
  };

  const saveCurrentAnswer = () => {
    const currentQ = questions[currentQuestion];
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestion] = {
      question: currentQ.question,
      selectedOption: optionChosen,
      weight: optionChosen ? optionChosen.weight : 0,
    };

    setUserAnswers(updatedAnswers);
    const updatedScores = [...scoreArray];
    updatedScores[currentQuestion] = optionChosen ? optionChosen.weight : 0;
    setScoreArray(updatedScores);
  };

  const nextQuestion = () => {
    if (!optionChosen) {
      alert("Please choose an option before proceeding.");
      return;
    }

    saveCurrentAnswer();
    setCurrentQuestion(currentQuestion + 1);
    setOptionChosen(null);
  };

  const previousQuestion = () => {
    if (currentQuestion > 0) {
      saveCurrentAnswer();
      setCurrentQuestion(currentQuestion - 1);
      setOptionChosen(null);
    }
  };

  const finishQuiz = async () => {
    if (!optionChosen) {
      alert("Please choose an option before finishing the survey.");
      return;
    }

    saveCurrentAnswer();

    const quizData = {
      userId: user.uid,
      s_id: survey.s_id,
      questions: survey.s_questions,
      survey_type: survey.s_survey_type,
      survey_type_1: survey.surveyType,
      s_title: survey.s_title,
      s_dateCompleted: new Date(),
      s_feedback: survey.s_feedback,
      answers: [
        ...userAnswers,
        {
          question: questions[currentQuestion].question,
          selectedOption: optionChosen,
          weight: optionChosen ? optionChosen.weight : 0,
        },
      ],
      scoreArray: [...scoreArray, optionChosen ? optionChosen.weight : 0],
    };

    try {
      const submissionResponse = await submitSurvey(quizData);
      setScore(submissionResponse.data.scoreArray);
      setGameState("finished");
    } catch (error) {
      console.error("Error posting survey data:", error);
    }
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AppNavbar />
      <CleanHeader />

      <Container className="Quiz bg-grey py-5">
        <Row className="justify-content-center mb-4">
          <Col md="8">
            <Card
              style={{
                marginTop: "20px",
                border: "0.01px solid",
                width: "100%",
                borderColor: "grey",
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <CardBody>
                <h2
                  className="text-center card-title"
                  style={{ padding: "10px" }}
                >
                  {questions[currentQuestion].question}
                </h2>
                <div className="questions">
                  {questions[currentQuestion].answers.map((answer, index) => (
                    <Button
                      key={index}
                      style={{
                        backgroundColor:
                          optionChosen && optionChosen.answer === answer.answer
                            ? "#050564"
                            : "white",
                        color:
                          optionChosen && optionChosen.answer === answer.answer
                            ? "white"
                            : "black",
                        border: "1px solid grey",
                      }}
                      onClick={() => chooseOption(answer)}
                      className="my-2 btn-block btn-secondary"
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "grey";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor =
                          optionChosen && optionChosen.answer === answer.answer
                            ? "#050564"
                            : "white";
                      }}
                    >
                      {answer.answer}
                    </Button>
                  ))}
                </div>

                <Row className="d-flex justify-content-between mt-3">
                  <Col xs="6">
                    <Button
                      style={{ backgroundColor: "#050564" }}
                      onClick={previousQuestion}
                      disabled={currentQuestion === 0}
                      className="w-100"
                    >
                      Previous
                    </Button>
                  </Col>

                  <Col xs="6">
                    {currentQuestion === questions.length - 1 ? (
                      <Button
                        color="success"
                        onClick={finishQuiz}
                        className="w-100"
                      >
                        Finish Quiz
                      </Button>
                    ) : (
                      <Button
                        style={{ backgroundColor: "#050564" }}
                        onClick={nextQuestion}
                        className="w-100"
                      >
                        Next Question
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <FooterBlack />
    </>
  );
}

export default Quiz;
