import React, { useEffect, useState } from "react";
import { getRecentSurveys, searchSurveys } from "../../services/survey.service";
import {
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Spinner,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";
const SurveyManagement = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    setLoading(true);
    try {
      const recentSurveys = await getRecentSurveys();
      setSurveys(recentSurveys.success ? recentSurveys.data : []);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setSurveys([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === "") {
      fetchSurveys();
      return;
    }

    setLoading(true);
    try {
      const searchedSurveys = await searchSurveys(searchQuery);
      setSurveys(searchedSurveys.success ? searchedSurveys.data : []);
    } catch (error) {
      console.error("Error searching surveys:", error);
      setSurveys([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Available Surveys</h2>
        <InputGroup
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Input
            type="text"
            placeholder="Search surveys..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginRight: "10px" }}
          />
          <InputGroupAddon addonType="append">
            <Button
              style={{ padding: "10px 20px", fontSize: "16px" }}
              color="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spinner color="primary" />
          </div>
        ) : surveys.length === 0 ? (
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            No surveys available
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
              marginTop: "30px",
              marginBottom: "50px",
            }}
          >
            {surveys.map((survey) => (
              <Card
                key={survey.s_id}
                style={{
                  flex: "1 1 calc(33.333% - 20px)",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "20px",
                }}
              >
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    {survey.s_title}
                  </CardTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", marginRight: "10px" }}
                      href={`/survey-page/${survey.s_id}`}
                    >
                      <Button color="primary" style={{ padding: "10px 30px" }}>
                        Edit
                      </Button>
                    </a>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        )}
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="/create-survey" style={{ textDecoration: "none" }}>
            <Button color="success">Create New Survey</Button>
          </a>
        </div>
      </div>
      <FooterBlack />
    </>
  );
};

export default SurveyManagement;
