import FooterBlack from "components/Footers/FooterBlack";
import AppNavbar from "components/Navbars/AppNavbar";
import Banner from "components/banner";
import React from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

function PublicSpeaking() {
  const backgroundImage = "url(" + require("assets/img/login-back-1.jpg") + ")";
  const [activePill, setActivePill] = React.useState("1");
  const [pillActive, setPillActive] = React.useState("chapter1");
  document.documentElement.classList.remove("nav-open");
  return (
    <>
      <AppNavbar />
      <Banner
        backgroundImage={backgroundImage}
        showLogo={false}
        title="PUBLIC SPEAKING"
        description="Get ready to command the stage"
      />
      <div
        className="features-5"
        style={{
          paddingTop: "20px",
          marginBottom: "25px",
          paddingBottom: "0px",
        }}
      >
        <Container>
          {/* Public Speaking Categories */}
          <Row>
            <div className="ml-auto mr-auto">
              <h2
                className="title text-center"
                style={{
                  color: "black",
                  paddingTop: "0px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                PUBLIC SPEAKING CATEGORIES
              </h2>
              <p
                className="description"
                style={{ color: "black", margin: "0px" }}
              >
                Apart from individuals and students, we also work with the
                following clients:
              </p>
            </div>
          </Row>
          <Row>
            <Col
              className="ml-auto mr-auto team-card-row"
              sm="5"
              style={{ marginBottom: "0px" }}
            >
              <div className="info">
                <h4 style={{ color: "black", marginTop: "0px" }}>
                  SCHOOLS AND UNIVERSITIES
                </h4>
                <p style={{ color: "black" }}>
                  Enhance the skill of speaking, engaging and presenting in the
                  education fraternity amongst learners and students. (Group
                  training)
                </p>
              </div>
            </Col>
            <Col
              className="mr-auto team-card-row"
              sm="5"
              style={{ marginBottom: "0px" }}
            >
              <div className="info">
                <h4 style={{ color: "black", marginTop: "0px" }}>
                  PROFESSIONALS AND MCS
                </h4>
                <p style={{ color: "black" }}>
                  Work on your technical speaking abilities for an upcoming
                  presenting opportunity and/or event. Stand out as we equip you
                  with interview skills and work related presentation skills.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="bottom-line">
            <Col
              className="ml-auto mr-auto team-card-row"
              sm="5"
              style={{ marginBottom: "0px" }}
            >
              <div className="info">
                <h4 style={{ color: "black", marginTop: "0px" }}>
                  CORPORATES AND BUSINESSES
                </h4>
                <p style={{ color: "black" }}>
                  Your employees need to be able to articulate themselves within
                  the corporate and business setting. We will facilitate
                  organizational trainings for staff for both internal and
                  external communication skills and techniques.
                </p>
              </div>
            </Col>
            <Col
              className="mr-auto team-card-row"
              sm="5"
              style={{ marginBottom: "0px" }}
            >
              <div className="info">
                <h4 style={{ color: "black", marginTop: "0px" }}>
                  SPEAKING AND COMPETITIONS
                </h4>
                <p style={{ color: "black" }}>
                  Whether you want to eloquently impress the judges at the
                  interview segment of a competition or carry speaking presence
                  as you deliver a speech, elevate africa academy will help you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="pricing-5"
        style={{
          paddingTop: "0px",
          paddingBottom: "30px",
          marginBottom: "80px",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "36px",
                  marginTop: "75px",
                }}
              >
                WHAT TO EXPECT?
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="3" />
            <Col md="6">
              <p
                className="description"
                style={{
                  fontWeight: "400",
                  color: "black",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                The course will be broken down into 4 main chapters
              </p>
              <div className="choose-plan">
                <Nav className="nav-pills-danger" pills role="tablist">
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter1");
                      }}
                    >
                      CHAPTER 1
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter2");
                      }}
                    >
                      CHAPTER 2
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter3");
                      }}
                    >
                      CHAPTER 3
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter4" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter4");
                      }}
                    >
                      CHAPTER 4
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <Col md="3" />
          </Row>
          <Row>
            <Col md="3" />
            <Col className="ml-auto" md="6">
              <TabContent activeTab={pillActive}>
                <TabPane tabId="chapter1">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        CHAPTER 1 - CONFIDENCE
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px", // Reduced marginBottom
                        }}
                      >
                        The first chapter of the speaker journey emphasizes on
                        making speakers aware that confidence is an essential
                        part of public speaking. This chapter will focus on
                        exercises in speaker confidence that show speakers that
                        public speaking is essentially a form of communication
                        that is not radically different from their day-to-day
                        communication.
                        <br />
                        <br />
                        Furthermore, it is necessary to include exercises that
                        help speakers to learn how to be comfortable saying
                        different types of speeches (Happy, sad etc). Ultimately
                        the chapter will provide training in speakers being
                        comfortable seeing themselves and thinking of themselves
                        as public speakers.
                      </p>
                      <img
                        src={require("../assets/img/public.jpeg")}
                        alt="Chapter 1"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px", // Added marginBottom
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="chapter2">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        CHAPTER 2 - ARTICULATION AND ENUNCIATION
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px", // Reduced marginBottom
                        }}
                      >
                        Two of the most essential aspects of public speaking are
                        articulation and enunciation. These two aspects loosely
                        defined refer to the production of clear sounds and
                        pronunciation of words formed from sounds. In this
                        chapter the focus will be on techniques that speakers
                        can use in forming clear sounds and pronouncing these
                        sounds.
                        <br />
                        <br />
                        It will focus on exercises such as tongue twisters which
                        make the speaker comfortable articulating and
                        enunciating usually difficult words in rapid succession,
                        additionally some mouth and lip stretching techniques
                        will be practiced allowing speakers to get used to
                        utilizing proper technique in speaking.
                      </p>
                      <img
                        src={require("../assets/img/enunciation.jpg")}
                        alt="Chapter 2"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px", // Added marginBottom
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="chapter3">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        CHAPTER 3 - BODY LANGUAGE
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px", // Reduced marginBottom
                        }}
                      >
                        Nonverbal cues or body language is a critical part of
                        public speaking. It is essential that speakers are aware
                        that their body language either aids or distracts from
                        the content and execution of their speech.
                        <br />
                        <br />
                        This chapter focuses on techniques such as proper use of
                        hand gestures, awareness, and correct posture as well as
                        movement that is necessary for a public speaker.
                        Additionally, it will also address exercises on facial
                        expressions.
                      </p>
                      <img
                        src={require("../assets/img/bodyLanguage.jpg")}
                        alt="Chapter 3"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px", // Added marginBottom
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="chapter4">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        CHAPTER 4 - VOICE PROJECTION
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px", // Reduced marginBottom
                        }}
                      >
                        This chapter ties closely with the first two chapters.
                        The chapter focuses on tonal and volume control in
                        speaking. It places an emphasis on exercises for
                        learning how to be audible without shouting and how to
                        be in control of your voice to aid in both clarity of
                        sounds and in confidence as well.
                        <br />
                        <br />
                        This chapter will help both with technique of voice
                        projection and information on the appropriate use of
                        different tones.
                      </p>
                      <img
                        src={require("../assets/img/projection.jpg")}
                        alt="Chapter 3"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px", // Added marginBottom
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
            <Col md="3" />
          </Row>
        </Container>
      </div>
      <FooterBlack />
    </>
  );
}

export default PublicSpeaking;
