import React, { useState } from "react";
import { Card, CardHeader, CardBody, UncontrolledCollapse } from "reactstrap";

const AccordionItem = ({ id, title, content, expanded }) => {
  const [isOpen, setIsOpen] = useState(expanded === "true");

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Card
      style={{
        borderRadius: "5px",
        marginBottom: "0px",
        borderBottom: "3px solid #black",
      }}
    >
      <CardHeader
        style={{ backgroundColor: "white", cursor: "pointer" }}
        id={`heading${id}`}
        role="tab"
        onClick={toggle}
      >
        <h5 style={{ marginBottom: 0 }}>
          <a
            aria-expanded={isOpen}
            href="#pablo"
            id={`collapse${id}`}
            style={{
              textDecoration: "none",
              color: "#333",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i
              className={`nc-icon ${
                isOpen ? "nc-minimal-down" : "nc-minimal-right"
              }`}
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                transition: "transform 0.3s ease",
              }}
            />
            {title}
          </a>
        </h5>
      </CardHeader>
      <UncontrolledCollapse
        isOpen={isOpen}
        aria-labelledby={`heading${id}`}
        role="tabpanel"
        toggler={`#collapse${id}`}
      >
        <CardBody style={{ padding: "15px" }}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
};

export default AccordionItem;
