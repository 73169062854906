import React from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import { Container, Row, Col, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import JciImage from "assets/img/demo/jci.png";
import CleanHeader from "components/Headers/CleanHeader";
import "../../Styling/App.css";

const JciToyp = () => {
  return (
    <div>
      <AppNavbar />
      <CleanHeader />
      <Container className="px-4 py-5">
        <h2
          style={{ color: "black", fontWeight: "bold", marginBottom: "20px" }}
        >
          JCI TOYP
        </h2>
        <Row className="align-items-stretch">
          <Col md="6" className="d-flex flex-column justify-content-between">
            <div>
              <p style={{ textAlign: "justify", marginBottom: "1rem" }}>
                Our Founder, Miss Akob, was recognised and honored as one of the
                Top 10 Young Persons in the World by Junior Chamber
                International. This was after she had initially been named one
                of the Top 10 Young Persons in South Africa at an award ceremony
                that was held in Durban. From there she went on to represent the
                country at the global selection where she emerged on the
                prestigious list of global game changers, leaders and
                innovators. Honorees are nominated and selected based on their
                achievements and leadership qualities in categories such as
                business, politics, culture, education, and humanitarian work.
              </p>
              <p style={{ textAlign: "justify", marginBottom: "1rem" }}>
                The JCI Ten Outstanding Young Persons of the World, usually JCI
                TOYP, program serves to formally recognize young people who
                excel in their chosen fields and exemplify the best attributes
                of the world's young people. The program is sponsored by Junior
                Chamber International (JCI). JCI selects 10 outstanding young
                people under 40 who live the JCI Mission in extraordinary ways.
              </p>
              <p style={{ textAlign: "justify", marginBottom: "1rem" }}>
                The JCI Ten Outstanding Young Persons (TOYP) Award exists to
                recognise outstanding individuals creating local and global
                impact through their work. The honorees and their stories of
                discovery, determination, and ingenuity inspire young active
                citizens to be better leaders, create better societies and take
                action to create sustainable impact.
              </p>
              <p style={{ textAlign: "justify", marginBottom: "1rem" }}>
                JCI is a membership-driven organization of dedicated and
                enterprising young leaders, aged 18 to 40, creating positive
                opportunities around the world. Active in more than 5000
                locations across nearly 115 countries, JCI Members are
                recognized for embracing new ideas and collaborating across
                borders. We support our members to develop their leadership
                skills as they relate to personal development, business and
                entrepreneurship, community action, and international
                collaboration.
              </p>
              <p style={{ textAlign: "justify", marginBottom: "1rem" }}>
                Ms. Elma Akob together with fellow JCI South Africa's Ten
                Outstanding Young Persons of 2022, Ms. Zakithi Mkhize, traveled
                to Hong Kong to accept their awards. We are proud to be led by
                this force of a woman whose life is a walking billboard of what
                Elevate Africa hopes to inspire in young people. Daring to
                dream, hard work and ethical leadership that is rooted in the
                growth of one's community. While we get the privilege of
                witnessing her brilliance and leadership skills in action it is
                heartwarming that the world sees it too. Congratulations and we
                wish you nothing short of the greatness you were destined for.
              </p>
            </div>
            <Button
              className="learn-more-btn"
              color="primary"
              tag={NavLink}
              to="/about-us"
              style={{ marginTop: "20px", alignSelf: "flex-start" }}
            >
              Back
            </Button>
          </Col>
          <Col md="6">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: "100%",
              }}
            >
              <div style={{ flex: 2 }}>
                <img
                  src="https://elevate-africa-academy.com/wp-content/uploads/2022/02/Elma-2-e1646047191822.jpeg"
                  style={{ width: "100%", height: "80%", objectFit: "cover" }}
                  alt="Elma Image"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={JciImage}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="JCI Image"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </div>
  );
};

export default JciToyp;
