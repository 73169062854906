import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardTitle, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styling/App.css";
import elmaImage from "../assets/profilepictures/Elmaprofilepicture.png";
import ponatshegoImage from "../assets/profilepictures/Ponatshegoprofilepicture.png";
import lihleImage from "../assets/img/lihleNew.jpeg";
const images = {
  "Elma Akob": elmaImage,
  "Ponatshego Sebola": ponatshegoImage,
  "Lihle Rita Khumalo": lihleImage,
};

const TeamMemberDescription = React.memo(({ member }) => {
  switch (member.t_name) {
    case "Elma Akob":
      return (
        <>
          <h4 className="team-title">Founder and CEO</h4>
          <p
            className="team-name"
            style={{ textAlign: "left", paddingBottom: "0px" }}
          >
            Elma Akob
          </p>
          <p className="team-description" style={{ marginBottom: "20px" }}>
            Elma has made a name for herself as an award-winning speaker and
            professional voice artist collaborating with multinational
            corporations such as DSTV, Disney, Netflix, and BBC Lifestyle. From
            audiobooks to animations, TV adverts, and automated voice generator.
          </p>
        </>
      );
    case "Ponatshego Sebola":
      return (
        <>
          <h4 className="team-title">Project Operations Director</h4>
          <p className="team-name">Ponatshego Sebola</p>
          <p className="team-description" style={{ marginBottom: "20px" }}>
            Being directly involved in the personal growth and improvement of
            others drives me. As a future Psychologist, I take pride in helping
            people to the best of my capabilities and hope to contribute to a
            psychology that is more African-centered and contextualized.
          </p>
        </>
      );
    case "Mbongeni Ncube":
      return (
        <>
          <h4 className="team-title">Corporate Operations Director</h4>
          <p className="team-name">Mbongeni Ncube</p>
          <p className="team-description" style={{ marginBottom: "20px" }}>
            Our humanity makes us superheroes. To make a difference we must be
            able to communicate our solutions clearly and effectively. One day I
            hope my voice will contribute towards international peace and
            security.
          </p>
        </>
      );
    case "Lihle Rita Khumalo":
      return (
        <>
          <h4 className="team-title">Administrator</h4>
          <p className="team-name">Lihle Rita Khumalo</p>
          <p className="team-description" style={{ marginBottom: "20px" }}>
            Lihle is a BCom graduate from the University of Pretoria. She is
            currently studying her BComHons in Strategic Management at the
            University of Johannesburg. Lihle is pursuing a budding career
            within the tech industry. She is an experienced public speaker with
            a passion for communication...
          </p>
        </>
      );
    default:
      return <p>No description available.</p>;
  }
});

const TeamMemberCard = React.memo(({ member, isSelected, onClick }) => (
  <div
    onClick={onClick}
    className={`team-card ${isSelected ? "active-card" : ""}`}
  >
    <img src={images[member.t_name]} alt={member.t_name} className="team-img" />
    <CardTitle
      style={{ margin: "0px", fontSize: "16px", textAlign: "left" }}
      className={`card-title ${isSelected ? "active-title" : ""}`}
    >
      {member.t_name}
    </CardTitle>
  </div>
));

// Memoize the fetch function
const fetchTeamData = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/team/getTeamDetails`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching team data:", error);
    return [];
  }
};

const TeamDisplay = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchTeamData();
      const sortedData = data.sort((a, b) => a.t_order - b.t_order);
      setTeamMembers(sortedData);
      setSelectedMember(
        sortedData.find((member) => member.t_name === "Elma Akob")
      );
    };

    loadData();
  }, []);

  // Memoize click handlers
  const handleCardClick = React.useCallback((member) => {
    setSelectedMember(member);
  }, []);

  const handleLearnMoreClick = React.useCallback(() => {
    navigate("/our-team");
  }, [navigate]);

  if (!selectedMember) return null;

  return (
    <Container>
      <Row>
        <Col
          style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
          md="3"
        >
          {teamMembers.map((member) => (
            <TeamMemberCard
              key={member.id}
              member={member}
              isSelected={selectedMember.t_name === member.t_name}
              onClick={() => handleCardClick(member)}
            />
          ))}
        </Col>
        <Col md="4">
          {selectedMember && (
            <img
              src={selectedMember.t_image}
              alt={selectedMember.t_name}
              className="selected-img"
            />
          )}
        </Col>
        <Col md="5" style={{ padding: "0px 35px" }}>
          {selectedMember && (
            <div style={{ textAlign: "left" }}>
              <TeamMemberDescription member={selectedMember} />
              <Button
                className="learn-more-btn"
                color="primary"
                onClick={handleLearnMoreClick}
              >
                Learn More
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(TeamDisplay);
