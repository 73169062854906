import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <Container className="py-5" style={{ minHeight: "calc(100vh - 200px)" }}>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div className="text-center p-5 bg-white shadow rounded">
              <svg
                className="mx-auto mb-4"
                width="64"
                height="64"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "#28a745" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <h1 className="h3 mb-4">Payment Successful!</h1>
              <p className="mb-4">
                Thank you for your purchase. Your transaction has been completed
                successfully.
              </p>
              <p className="text-muted mb-4">
                Redirecting to homepage in 5 seconds...
              </p>
              <Button tag={Link} to="/" color="primary">
                Return to Home
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </>
  );
};

export default SuccessPage;
