import React from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import { Container, Row, Col, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import ElmaImage from "assets/img/champions.jpeg";
import JciImage from "assets/img/championships.jpeg";
import "../../Styling/App.css";

const YouthOwnedBrand = () => {
  return (
    <div>
      <AppNavbar />
      <CleanHeader />
      <Container className="px-4 py-5">
        <strong>
          <h2
            style={{
              color: "black",
              fontWeight: "bold",
              marginBottom: "45px",
              marginTop: "0px",
            }}
          >
            2022 Youth Owned Education Brand Award
          </h2>
        </strong>
        <Row>
          <Col md="6" style={{ color: "black", marginBottom: "15px" }}>
            <p className="text-dark">
              Established in 2022, the top 16 youth-owned brands awards are a
              platform that is aimed at recognizing and celebrating youth
              excellence. The top 16 youth-owned brands awards acknowledges that
              young people are not only contributing to the economy of South
              Africa, but the overall image of the country.
            </p>
            <p className="text-dark">
              In a time where the unemployment rate is at an all-time low, these
              awards celebrate brands that create jobs in the process of honing
              their craft. The awards celebrate youth-owned brands in 16
              categories, namely; Agriculture, Apparel, Creative and Arts,
              Education and Literature, Footwear and Accessories, Food and
              Beverage, Hair and Beauty, Health and Wellness, Homeware,
              Manufacturing, Media, NPO's and Charities, Personalities and
              Influencers, Services, Technology, Travel and Tourism. Elevate
              Africa Academy won the inaugural award for the top youth owned
              education brand. The Top 16 Youth-Owned Brands Awards are the GOLD
              STANDARD OF YOUTH EXCELLENCE.
            </p>
            <Button
              color="primary"
              tag={NavLink}
              to="/about-us"
              style={{ marginTop: "20px" }}
              className="learn-more-btn"
            >
              Back
            </Button>
          </Col>
          <Col md="5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <img
                  src={ElmaImage}
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                    marginBottom: "20px",
                  }}
                  alt="Elma Image"
                />
                <img
                  src={JciImage}
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                  }}
                  alt="JCI Image"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </div>
  );
};

export default YouthOwnedBrand;
