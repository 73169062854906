import FooterBlack from "components/Footers/FooterBlack";
import AppNavbar from "components/Navbars/AppNavbar";
import Banner from "components/banner";
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

function VoiceOver() {
  const backgroundImage =
    "url(" + require("assets/img/sections/mic-background.jpg") + ")";
  const [pillActive, setPillActive] = React.useState("chapter1");
  document.documentElement.classList.remove("nav-open");

  return (
    <>
      <AppNavbar />
      <Banner
        backgroundImage={backgroundImage}
        showLogo={false}
        title="VOICE OVER"
        description="Get ready to capture the audience with your voice"
      />
      <div
        className="pricing-5"
        style={{ paddingTop: "0px", paddingBottom: "30px" }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "36px",
                  marginTop: "75px",
                }}
              >
                WHAT TO EXPECT?
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="4" />
            <Col md="5">
              <p
                className="description"
                style={{
                  fontWeight: "400",
                  color: "black",
                  marginBottom: "15px",
                  marginTop: "10px",
                }}
              >
                The course will be broken into 3 main lessons
              </p>
              <div className="choose-plan">
                <Nav className="nav-pills-danger" pills role="tablist">
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter1");
                      }}
                    >
                      LESSON 1
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter2");
                      }}
                    >
                      LESSON 2
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillActive === "chapter3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPillActive("chapter3");
                      }}
                    >
                      LESSON 3
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <Col md="3" />
          </Row>
          <Row>
            <Col md="3" />
            <Col className="ml-auto" md="6">
              <TabContent activeTab={pillActive}>
                <TabPane tabId="chapter1">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        LESSON 1 - VOICE OVER THEORY
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Before you step into the industry as an artist it is
                        important to know the terminology used by clients, what
                        to expect and an analysis of the Voice over market. In
                        this lesson you will learn all the different styles that
                        exist in the industry along with practical examples to
                        expose you to the endless possibilities of being a voice
                        over artist from various accents to delivery and how to
                        tap into all of these.
                        <br />
                        <br />
                        You will also learn how to position yourself in the
                        industry and how to stand out by setting up a
                        proprietary elevate africa academy ‘audio CV’.
                      </p>
                      <div style={{ marginTop: "30px" }}>
                        <img
                          src={require("../assets/img/voice.jpeg")} // Update this path to match your actual image file location
                          alt="Voice Over Artist Recording"
                          style={{
                            width: "100%",
                            maxHeight: "350px", // Increased height to show more of the image
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "center 30%", // Adjust this to focus on the face and mic
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="chapter2">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        LESSON 2 - VOICE OVER SKILLS
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        With Voice over skills you will put the theory from
                        Lesson 1 into practice as the trainer will guide you
                        through how to identify different styles and how to
                        perform these styles using voice variation, tonal
                        inflection, speed differentiation, and facial
                        expressions. You will also learn breathing techniques,
                        how to articulate and enunciate whilst using your
                        diaphragm and tools of improvement to meet your
                        individual needs.
                      </p>
                      <img
                        src={require("../assets/img/v2.jpg")}
                        alt="Voice Over Skills"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="chapter3">
                  <div className="space-top" />
                  <Row>
                    <Col md="12">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          marginTop: "0px",
                        }}
                      >
                        LESSON 3 - VOICE OVER TECHNICALITIES
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontWeight: "400",
                          color: "black",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Lastly, because elevate africa academy believes in
                        holistic training, we provide post-recording training
                        such as how to record your own Voice over and edit with
                        the best software, what equipment to buy and where to
                        purchase it, as well as how to set up your home studio.
                        <br />
                        <br />
                        We end it off with a surprise gift for everyone who
                        graduates from the course.
                      </p>
                      <img
                        src={require("../assets/img/flat-lay-composition-with-microphone-podcasts-black-studio-headphones-brown-background-with-coffee-laptop-learning-online-education-conceptxa.jpg")}
                        alt="Voice Over Technicalities"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
            <Col md="3" />
          </Row>
        </Container>
      </div>
      <FooterBlack />
    </>
  );
}

export default VoiceOver;
