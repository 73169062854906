import React from "react";
import { Container, Row, Col } from "reactstrap";
import Award31 from "assets/img/demo/publicspeaking.png";
import Award32 from "assets/img/demo/publicspeaking2.png";
import Award33 from "assets/img/demo/publicspeaking3.png";
import Award34 from "assets/img/demo/publicspeaking4.png";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import "../../Styling/App.css";

const PublicSpeakingChampionships = () => {
  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <Container className="px-4 py-5 mb-5">
        <h2 style={{ fontWeight: "bold", marginBottom: "30px" }}>
          2022 UP Public Speaking Championships
        </h2>
        <Row>
          <Col md="6">
            <p style={{ textAlign: "justify", marginBottom: "1.5rem" }}>
              Elevate Africa Academy launched the first-ever Public Speaking
              Championships at the University of Pretoria. This was a
              groundbreaking collaboration between Elevate Africa Academy and
              the UP SRC, directly contributing to our company's objective of
              amplifying African voices. Young people came and showed us just
              what they have to give towards the elevation of our country and
              continent. Seven finalists were chosen from among the 40+
              contestants and competed in front of an eminent panel of judges
              that included Miss South Africa 2010 and businesswoman, Bokang
              Montjane Tshabalala, Sadika Fakir, Integrated Media & Digital
              Director at Tiger Brands, and Lennox Wasara, a podcaster and radio
              personality.
            </p>
            <p style={{ textAlign: "justify", marginBottom: "1.5rem" }}>
              Congratulations to Miss Onyinyechuku Maryjane Igbojinna, 2022 UP
              Public Speaking Champion. Miss Onyinyechuku remarked after winning
              the tournament, "Honestly speaking, I am very grateful to God, my
              family, and the trainers for pushing us to get out of our comfort
              zones." Her selected topic was "Content Creation as a Solution to
              Unemployment." She explained that she chose it after noticing how
              much content she consumes in her own life and the impact it has on
              viewers and creators. From spanning boundaries and connecting
              people to entertaining the entire world during a frightening
              pandemic, but most importantly, financially empowering so many
              people. Igbojinna indicated that she advises more students to take
              part in public speaking – "public speaking is such a vital skill
              that we all need to have because when we grow up one day, whether
              we are healthcare workers or in the corporate world, you need to
              learn how to communicate effectively." Along with the winner,
              there was also a prize for the most improved speaker during the
              tournament, who was Sibusiso Mziyako. Mziyako is a debater who was
              persuaded to compete by a buddy who spotted his ability. "In
              reality, my friend forced me to enter." "This is my first
              competition, and the experience I gained in debate has really
              helped me transition into public speaking," Mziyako said.
            </p>
          </Col>
          <Col md="6">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div style={{ flex: 2, marginBottom: "10px" }}>
                <img
                  src={Award31}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="Public speaking1"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={Award32}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="Public speaking 2"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col md="6">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div style={{ flex: 1, marginBottom: "10px" }}>
                <img
                  src={Award33}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="Public speaking3"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={Award34}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="Public speaking 4"
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <p style={{ textAlign: "justify", marginBottom: "1.5rem" }}>
              This event could not have been the success it was without the
              immeasurable support given by our partners and the hard work of
              our team. We also thank the University of Pretoria SRC for seeing
              the power of public speaking in the same way we do. Montjane
              Tshabalala told us that "I think it's extremely important, for
              whatever industry you want to go into you need to be a good
              communicator, whether it's in a boardroom, whether it's in a
              plant, at the end of the day communication skills and public
              speaking skills I personally feel are skills each and every person
              should have''.
            </p>
            <p style={{ textAlign: "justify", marginBottom: "1.5rem" }}>
              The highlight of the event for Montjane Tshabalala was that the
              theme was about South Africa – "listen, for me it was for the fact
              that the topic was South Africa, and here I was looking at seven
              different young people in our country and they all touched on
              something totally different, and I thought to myself how different
              was that." She went on to further indicate that the topics were
              thought provoking, they got me thinking, got me looking deep
              inside and I thought to myself, oh my goodness, the excellence of
              our people, especially the young people. The youth actually has
              solutions to the problems that are currently taking place, not
              just in South Africa, but on the continent". Lennox Wasara who sat
              as one of our judging panelists commented that "I think it's very
              important to pass on the knowledge to the next generation of
              speakers and help people to be equipped to speak their message
              confidently and comfortably".
            </p>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </>
  );
};

export default PublicSpeakingChampionships;
