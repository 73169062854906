import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage: "url(" + require("assets/img/sections/mic-background.jpg") + ")",
          position: "relative",
          height: "50px", // Reduced height
          paddingBottom: "5px", // Reduced padding to accommodate cards
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <Row>
              <Col md="4">
                <img
                  alt="..."
                  className="img-responsive img-no-padding"
                  src={require("assets/img/ElevateAfrica_White-2.png")}
                  height={200} // Reduced height of the image
                />
              </Col>
              <Col md="8">
                <div className="motto">
                  <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <h2 style={{ fontWeight: "bold" }}>
                      UNLOCK YOUR VOICE: MASTER PUBLIC SPEAKING AND VOICE OVER SKILLS
                    </h2>
                  </div>
                  <h4 className="description">
                    <small style={{ color: "white" }}>
                      Communicate effectively with elevate africa academy and take your career to the next level with public speaking and voice over acting training courses on offer.
                    </small>
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container style={{ marginTop: "-50px" }}> {/* Adjusted negative margin to pull cards up */}
        <Row>
          <Col md="3">
            <Card>
              <CardBody>
                <CardTitle tag="h5">Card 1</CardTitle>
                <CardText>This is some text within a card body.</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardBody>
                <CardTitle tag="h5">Card 2</CardTitle>
                <CardText>This is some text within a card body.</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardBody>
                <CardTitle tag="h5">Card 3</CardTitle>
                <CardText>This is some text within a card body.</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardBody>
                <CardTitle tag="h5">Card 4</CardTitle>
                <CardText>This is some text within a card body.</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LandingPageHeader;
