import React, { useEffect, useState, useContext } from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import { getCompletedSurveys } from "services/user.service";
import { getAllSurveys } from "services/survey.service";
import { getAuth } from "firebase/auth";
import { Container, Row, Col, Button } from "reactstrap";
import Quiz from "./Quiz";
import EndScreen from "./EndScreen";
import { GameStateContext } from "../../assets/Helpers/gameContexts";
import QuizCard from "./QuizCard";
import UserReport from "./UserReport";
import "../../Styling/FeedbackModal.css";

const auth = getAuth();

const generateUserReport = async (userId) => {
  try {
    const completedSurveysResponse = await getCompletedSurveys(userId);
    const allSurveysResponse = await getAllSurveys(userId);

    if (!completedSurveysResponse.success || !allSurveysResponse.success) {
      throw new Error("Failed to fetch survey data");
    }

    const completedSurveys = completedSurveysResponse.data;
    const allSurveys = allSurveysResponse.data;

    const totalQuizzes = allSurveys.length;
    const completedQuizzes = completedSurveys.length;
    const progressPercentage = (completedQuizzes / totalQuizzes) * 100;

    const reportDetails = completedSurveys.map((quiz) => {
      const feedback = quiz.s_feedback
        ? quiz.s_feedback
            .slice()
            .sort((a, b) => b.score - a.score)
            .find((f) => f.score <= quiz.totalScore)
        : null;

      return {
        title: quiz.s_title,
        totalScore: quiz.totalScore,
        surveyTotal: quiz.surveyTotal,
        feedback: feedback ? feedback.feedback : "No feedback available",
        completedOn: convertTimestamp(quiz.s_dateCompleted),
      };
    });

    const areasForImprovement = reportDetails.filter(
      (quiz) => quiz.totalScore < quiz.surveyTotal / 2
    );

    const report = {
      userId,
      totalQuizzes,
      completedQuizzes,
      progressPercentage,
      reportDetails,
      areasForImprovement,
    };

    return {
      success: true,
      report,
    };
  } catch (error) {
    console.error("Error generating user report:", error);
    return {
      success: false,
      message: "Error generating user report",
      error: error.message,
    };
  }
};

const convertTimestamp = (timestamp) => {
  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const SurveyDashboard = () => {
  const [user, setUser] = useState(null);
  const [takenQuizzes, setTakenQuizzes] = useState([]);
  const [quizzesToBeTaken, setQuizzesToBeTaken] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [report, setReport] = useState(null);
  const [activeSection, setActiveSection] = useState("quizzesToComplete");
  const { gameState } = useContext(GameStateContext);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    const getSurveys = async () => {
      if (user) {
        try {
          const CompletedSurveys = await getCompletedSurveys(user.uid);
          const allSurveys = await getAllSurveys(user.uid);
          const allSurveysData = allSurveys.data;
          const CompletedSurveysData = CompletedSurveys.data || [];

          const takenQuizIds = CompletedSurveysData.map((quiz) => quiz.s_id);
          const filteredQuizzes = allSurveysData.filter(
            (quiz) => !takenQuizIds.includes(quiz.s_id)
          );
          setQuizzesToBeTaken(filteredQuizzes);
          setTakenQuizzes(CompletedSurveysData);
        } catch (error) {
          console.error("Error fetching completed surveys: ", error);
        }
      }
    };
    getSurveys();
  }, [user]);

  const handleTakeQuiz = (quizId) => {
    setSelectedQuizId(quizId);
  };

  const handleGenerateReport = async () => {
    if (user) {
      const response = await generateUserReport(user.uid);
      if (response.success) {
        setReport(response.report);
        setActiveSection("report");
      } else {
        console.error("Error generating report:", response.message);
      }
    }
  };

  if (gameState === "finished") {
    return <EndScreen />;
  }

  if (selectedQuizId) {
    return <Quiz id={selectedQuizId} />;
  }

  const activeTabStyle = {
    backgroundColor: "#000080",
    color: "white",
  };

  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <div className="blur-background">
        <Container className="mt-4" style={{ marginBottom: "90px" }}>
          <div className="d-flex justify-content-center mb-4">
            <Button
              color="info"
              onClick={() => setActiveSection("quizzesToComplete")}
              className="mx-2"
              style={
                activeSection === "quizzesToComplete" ? activeTabStyle : {}
              }
            >
              Surveys to Complete
            </Button>
            <Button
              color="info"
              onClick={() => setActiveSection("completedQuizzes")}
              className="mx-2"
              style={activeSection === "completedQuizzes" ? activeTabStyle : {}}
            >
              Completed Surveys
            </Button>
            <Button
              color="info"
              onClick={handleGenerateReport}
              className="mx-2"
              style={activeSection === "report" ? activeTabStyle : {}}
            >
              Generate Report
            </Button>
          </div>
          {activeSection === "quizzesToComplete" && (
            <>
              <div className="mb-4"></div>
              <Row>
                {quizzesToBeTaken.length > 0 ? (
                  quizzesToBeTaken.map((quiz, index) => (
                    <QuizCard
                      key={index}
                      quiz={quiz}
                      isTaken={false}
                      onTakeQuiz={handleTakeQuiz}
                    />
                  ))
                ) : (
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <h5
                      style={{
                        color: "green",
                        marginTop: "15px",
                      }}
                    >
                      All assigned surveys are complete.
                    </h5>
                  </Col>
                )}
              </Row>
            </>
          )}
          {activeSection === "completedQuizzes" && (
            <>
              <div className="mt-5 mb-4"></div>
              <Row>
                {takenQuizzes.map((quiz, index) => (
                  <QuizCard key={index} quiz={quiz} isTaken={true} />
                ))}
              </Row>
            </>
          )}
          {activeSection === "report" && report && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <UserReport report={report} />
            </div>
          )}
        </Container>
      </div>
      <FooterBlack />
    </>
  );
};

export default SurveyDashboard;
