import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import Values from "../assets/img/Green and Black Minimalist Business Strategy Analysis Checklist Instagram Post(3).svg";
import ElevatePic from "../assets/img/ElevateAfrica_White-2.png";
import FooterBlack from "components/Footers/FooterBlack";
import AwardCard from "views/AwardCard";
import SponsorshipSection from "views/LandingPageSections/SponsorSection";
import { getTeam } from "../service/aboutUs";
import "../Styling/App.css";
import Banner from "components/banner";

function AboutUs() {
  const [teamMembers, setTeamMembers] = useState([]);
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTeam();
        console.log("Fetched team data:", data);
        console.log("Fetched team data:", data);
        const arrayofMembers = data.data;
        const sortedMembers = arrayofMembers.sort(
          (a, b) => a.t_order - b.t_order
        );

        setTeamMembers(sortedMembers);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <AppNavbar />
      <Banner showLogo={false} title="" />

      {/* Vision and Mission Section */}
      <Container style={{ paddingTop: "60px" }}>
        <h2
          className="text-center my-5"
          style={{
            fontWeight: "bold", // Bold font style
            color: "black",
            fontSize: "36px",
            // Text color
            textTransform: "uppercase", // All uppercase letters
          }}
        >
          Vision and Mission Statements
        </h2>
        <Row className="my-5 text-center">
          <Col md="6">
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#113B51",
              }}
            >
              <CardImg
                top
                src={ElevatePic} // Add your image path here
                alt="Elevate Image"
                style={{ height: "200px", objectFit: "contain" }}
              />
              <CardBody style={{ height: "300px", color: "white" }}>
                {" "}
                {/* Set a fixed height */}
                <CardTitle
                  tag="h5"
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "white",
                  }}
                >
                  Our Vision
                </CardTitle>
                <CardText style={{ fontSize: "16px", color: "#fff" }}>
                  Our vision is to become the leading platform in Africa for
                  cultivating eloquent, confident, and impactful speakers who
                  will inspire, lead, and drive positive change across the
                  continent.
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#113B51",
              }}
            >
              <CardImg
                top
                src={ElevatePic} // Add your image path here
                alt="Elevate Image"
                style={{ height: "200px", objectFit: "contain" }}
              />
              <CardBody style={{ height: "300px", color: "white" }}>
                {" "}
                {/* Set a fixed height */}
                <CardTitle
                  tag="h5"
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "white",
                  }}
                >
                  Our Mission
                </CardTitle>
                <CardText style={{ fontSize: "16px", color: "#fff" }}>
                  Elevate Africa Academy is dedicated to empowering leaders,
                  entrepreneurs, and professionals by harnessing the
                  transformative power of public speaking. We provide
                  comprehensive training that boosts confidence, hones
                  communication skills, and fosters personal and professional
                  development, enabling our participants to articulate their
                  visions, embrace their unique voices, and command any
                  audience.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Our Values Section with Full-Width Image */}
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: "20px 0",
        }}
      >
        <h2
          className="text-center my-5"
          style={{
            fontWeight: "bold",
            color: "black",
            fontSize: "clamp(24px, 5vw, 36px)",
            textTransform: "uppercase",
          }}
        >
          Our Values
        </h2>
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            maxWidth: "1400px",
            margin: "0 auto",
            padding: "0 15px",
          }}
        >
          <img
            src={Values}
            alt="Our Values"
            className="values-section"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "600px",
              objectFit: "contain",
              display: "block",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <div className="blog-4">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2
                className="text-center"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "0px",
                  fontSize: "clamp(24px, 5vw, 36px)",
                  paddingTop: "clamp(30px, 5vw, 60px)",
                }}
              >
                AWARDS AND RECOGNITION
              </h2>
              <br />
            </Col>
          </Row>
          <Row>
            <AwardCard
              imgSrc={require("assets/img/yobimage.png")}
              title="2022 TOP 16 YOUTH OWNED BRANDS AWARD"
              description="The main aim of Yoba is to recognise the excellence, innovation and hard work of South African youth-owned brands across 16 categories."
              link="/youth-owned-brands"
            />
            <AwardCard
              imgSrc={require("assets/img/demo/jci.png")}
              title="JCI Ten Outstanding Young Persons Of The World"
              description="Miss Akob, our founder, was named one of the Top 10 Young People Under 40 in the World by JCI (Junior Chamber International), for the year 2022."
              link="/jci-toyp"
            />
            <AwardCard
              imgSrc={require("assets/img/demo/publicspeaking.png")}
              title="2022 UP Public Speaking Championships"
              description="Elevate Africa Academy launched the first ever Public Speaking Championships at the University of Pretoria. This was a ground-breaking collaboration between Elevate Africa Academy and the UP SRC, directly contributing to our company's objective of amplifying African voices."
              link="/2022-up-public-speaking-championships"
            />
          </Row>
          <div className="space-top" />
        </Container>
      </div>

      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <div className="space-top" />
            <h2 className="title">SPONSORSHIPS</h2>
          </Col>
        </Row>
        <SponsorshipSection />
      </Container>

      <div
        className="project-4 section"
        style={{ paddingTop: "20px", paddingBottom: "0px" }}
      ></div>
      <FooterBlack />
    </>
  );
}

export default AboutUs;
