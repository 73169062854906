import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Progress,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import "../Styling/App.css";
import "../Styling/Quiz.css";
import { CSSTransition, SwitchTransition } from 'react-transition-group'; // Import for animations

function SpeakingAssessmentQuiz() {
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('next'); // For animation direction
  const [startQuiz, setStartQuiz] = useState(false); // Track if quiz has started

  // Quiz sections and questions
  const quizSections = [
    {
      title: "Confidence",
      description: "This section evaluates your comfort level with public speaking",
      maxPoints: 25,
      questions: [
        {
          id: "q1",
          text: "Have you ever spoken in front of an audience (e.g., presentations, speeches, debates, or meetings)?",
          options: [
            { text: "Yes, frequently", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Rarely", value: 3 },
            { text: "Never", value: 2 }
          ]
        },
        {
          id: "q2",
          text: "How do you feel before speaking in front of a group?",
          options: [
            { text: "Excited and confident", value: 5 },
            { text: "A little nervous but manageable", value: 4 },
            { text: "Very anxious and hesitant", value: 3 },
            { text: "I avoid public speaking whenever possible", value: 2 }
          ]
        },
        {
          id: "q3",
          text: "Do you often doubt your ability to deliver a speech effectively?",
          options: [
            { text: "Never", value: 5 },
            { text: "Sometimes", value: 4 },
            { text: "Often", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q4",
          text: "Have you ever received feedback that you seemed nervous while speaking?",
          options: [
            { text: "No, I usually appear confident", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Frequently", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q5",
          text: "Do you feel comfortable answering unexpected questions in front of an audience?",
          options: [
            { text: "Yes, I can think on my feet", value: 5 },
            { text: "Sometimes, but it depends on the topic", value: 4 },
            { text: "No, I get flustered easily", value: 3 },
            { text: "I avoid situations where I might be asked questions", value: 2 }
          ]
        }
      ]
    },
    // Other sections remain unchanged
    {
      title: "Voice Projection",
      description: "This section evaluates how effectively you can be heard",
      maxPoints: 25,
      questions: [
        {
          id: "q6",
          text: "Have you ever been told that people struggle to hear you when you speak?",
          options: [
            { text: "Never", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Frequently", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q7",
          text: "How would you describe your speaking volume in a presentation?",
          options: [
            { text: "Loud and clear", value: 5 },
            { text: "Moderate but audible", value: 4 },
            { text: "Soft, and sometimes people struggle to hear me", value: 3 },
            { text: "Too soft, and I'm often asked to speak louder", value: 2 }
          ]
        },
        {
          id: "q8",
          text: "Do you adjust your speaking volume depending on the size of the audience and room?",
          options: [
            { text: "Yes, I do this naturally", value: 5 },
            { text: "I try, but I'm not always consistent", value: 4 },
            { text: "I struggle with this", value: 3 },
            { text: "I never think about it", value: 2 }
          ]
        },
        {
          id: "q9",
          text: "Have you ever used a microphone while speaking?",
          options: [
            { text: "Yes, and I felt comfortable", value: 5 },
            { text: "Yes, but I found it challenging", value: 4 },
            { text: "No, but I would be comfortable trying", value: 3 },
            { text: "No, and I would feel nervous using one", value: 2 }
          ]
        },
        {
          id: "q10",
          text: "How comfortable are you with varying your tone and pitch while speaking?",
          options: [
            { text: "Very comfortable - I naturally use vocal variety", value: 5 },
            { text: "Somewhat comfortable - I do this occasionally", value: 4 },
            { text: "Not very comfortable - My tone tends to be monotone", value: 3 },
            { text: "Uncomfortable - I never think about varying my tone", value: 2 }
          ]
        }
      ]
    },
    {
      title: "Body Language",
      description: "This section evaluates your non-verbal communication",
      maxPoints: 25,
      questions: [
        {
          id: "q11",
          text: "How often do you use gestures (hand movements, facial expressions) while speaking?",
          options: [
            { text: "Always", value: 5 },
            { text: "Sometimes", value: 4 },
            { text: "Rarely", value: 3 },
            { text: "Never", value: 2 }
          ]
        },
        {
          id: "q12",
          text: "Have you ever been told that your body language appears stiff or unnatural when speaking?",
          options: [
            { text: "No, I appear natural and expressive", value: 5 },
            { text: "Occasionally, but I try to improve", value: 4 },
            { text: "Frequently, I struggle with body language", value: 3 },
            { text: "Always, I feel very stiff when speaking", value: 2 }
          ]
        },
        {
          id: "q13",
          text: "Do you make eye contact with your audience while speaking?",
          options: [
            { text: "Always", value: 5 },
            { text: "Sometimes", value: 4 },
            { text: "Rarely", value: 3 },
            { text: "Never", value: 2 }
          ]
        },
        {
          id: "q14",
          text: "Do you move naturally when speaking, or do you feel stuck in one position?",
          options: [
            { text: "I move naturally and comfortably", value: 5 },
            { text: "I try to move but feel a bit awkward", value: 4 },
            { text: "I stay in one place but want to improve", value: 3 },
            { text: "I feel completely stuck and avoid moving", value: 2 }
          ]
        },
        {
          id: "q15",
          text: "Are you aware of your posture when speaking in front of others?",
          options: [
            { text: "Yes, I consciously maintain good posture", value: 5 },
            { text: "Sometimes, but I could improve", value: 4 },
            { text: "Rarely think about it", value: 3 },
            { text: "No, I'm often told I slouch or appear closed off", value: 2 }
          ]
        }
      ]
    },
    {
      title: "Articulation",
      description: "This section evaluates your clarity of speech",
      maxPoints: 25,
      questions: [
        {
          id: "q16",
          text: "Do you ever struggle to pronounce certain words while speaking?",
          options: [
            { text: "Never", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Frequently", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q17",
          text: "Have people ever told you that they struggle to understand your speech due to unclear pronunciation?",
          options: [
            { text: "No, never", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Frequently", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q18",
          text: "Do you tend to speak too fast when nervous?",
          options: [
            { text: "No, I maintain a steady pace", value: 5 },
            { text: "Occasionally", value: 4 },
            { text: "Frequently", value: 3 },
            { text: "Always", value: 2 }
          ]
        },
        {
          id: "q19",
          text: "Do you read or speak aloud at work/school?",
          options: [
            { text: "Yes, everyday my work requires me to read or speak aloud", value: 5 },
            { text: "Yes, my work requires me to often read or speak aloud", value: 4 },
            { text: "Rarely, I speak and read aloud when asked to", value: 3 },
            { text: "No, never I am not required to talk at work", value: 2 }
          ]
        },
        {
          id: "q20",
          text: "If English is not your first language, do you feel comfortable speaking it in public?",
          options: [
            { text: "Yes, very comfortable", value: 5 },
            { text: "Somewhat comfortable", value: 4 },
            { text: "Not very comfortable", value: 3 },
            { text: "Very uncomfortable", value: 2 }
          ]
        }
      ]
    },
    {
      title: "Final Reflection",
      description: "Let's complete your assessment",
      maxPoints: 10,
      questions: [
        {
          id: "q21",
          text: "What is your main goal for improving your public speaking skills?",
          type: "textarea",
          value: 5
        },
        {
          id: "q22",
          text: "On a scale of 1-10, how confident are you in your overall public speaking ability?",
          type: "select",
          options: [
            { text: "1 - Not confident at all", value: 1 },
            { text: "2", value: 1 },
            { text: "3", value: 3 },
            { text: "4", value: 3 },
            { text: "5", value: 3 },
            { text: "6", value: 4 },
            { text: "7", value: 4 },
            { text: "8", value: 5 },
            { text: "9", value: 5 },
            { text: "10 - Very confident", value: 5 }
          ]
        }
      ]
    }
  ];

  // Calculate total questions
  const totalQuestions = quizSections.reduce((total, section) => total + section.questions.length, 0);

  // Update progress whenever current question or section changes
  useEffect(() => {
    const currentQuestionIndex = quizSections.slice(0, currentSection).reduce(
      (total, section) => total + section.questions.length, 0) + currentQuestion;

    const progressPercentage = (currentQuestionIndex / totalQuestions) * 100;
    setProgress(progressPercentage);
  }, [currentSection, currentQuestion, totalQuestions]);

  // Handler for selecting an answer
  const handleAnswerSelect = (questionId, value) => {
    setAnswers({
      ...answers,
      [questionId]: value
    });
  };

  // Handler for starting the quiz
  const handleStartQuiz = () => {
    setStartQuiz(true);
  }

  // Navigate to next question
  const nextQuestion = () => {
    setAnimationDirection('next');
    const currentSectionQuestions = quizSections[currentSection].questions;

    if (currentQuestion < currentSectionQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentSection < quizSections.length - 1) {
      setCurrentSection(currentSection + 1);
      setCurrentQuestion(0);
    } else {
      // End of quiz, calculate results
      calculateResults();
    }
  };

  // Navigate to previous question
  const prevQuestion = () => {
    setAnimationDirection('prev');
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      setCurrentQuestion(quizSections[currentSection - 1].questions.length - 1);
    }
  };

  // Calculate quiz results
  const calculateResults = () => {
    setLoading(true);

    // Simulate API call or processing
    setTimeout(() => {
      let totalScore = 0;

      // Calculate total score
      Object.keys(answers).forEach(questionId => {
        totalScore += answers[questionId];
      });

      // Determine category based on score range
      let resultCategory = "";
      if (totalScore >= 85) {
        resultCategory = "Public Speaking Master";
      } else if (totalScore >= 70) {
        resultCategory = "Strong Speaker";
      } else if (totalScore >= 50) {
        resultCategory = "Developing Speaker";
      } else {
        resultCategory = "Beginner Speaker";
      }

      setScore(totalScore);
      setCategory(resultCategory);
      setLoading(false);
      setIsResultModalOpen(true);
    }, 1500);
  };

  // Toggle results modal
  const toggleResultModal = () => {
    setIsResultModalOpen(!isResultModalOpen);
  };

  // Get result description based on category
  const getResultDescription = () => {
    switch (category) {
      case "Public Speaking Master":
        return (
          <>
            <p>You have strong public speaking skills! You are confident, articulate, and engaging. Your voice is well-projected, and your body language supports your message. While you may have minor areas for refinement, you can command an audience effectively.</p>
            <h5>What we will do in this course to enhance your skills:</h5>
            <ul>
              <li>Advanced storytelling techniques to make your speeches more compelling.</li>
              <li>Fine-tuning vocal variety to add more depth and dynamism to your speaking style.</li>
              <li>High-level audience engagement strategies to enhance interaction and persuasion.</li>
              <li>Crisis communication & impromptu speaking to ensure you can handle unexpected moments seamlessly.</li>
            </ul>
          </>
        );
      case "Strong Speaker":
        return (
          <>
            <p>You are a good speaker with solid foundational skills, but there are still areas that need polishing. You might struggle with nervousness, minor clarity issues, or occasional body language stiffness.</p>
            <h5>What we will do in this course to enhance your skills:</h5>
            <ul>
              <li>Confidence-building exercises to eliminate self-doubt before public speaking.</li>
              <li>Techniques for commanding presence to strengthen your delivery and engagement.</li>
              <li>Speech clarity training to ensure every word is powerful and easy to understand.</li>
              <li>Vocal modulation techniques to make your voice more engaging and dynamic.</li>
            </ul>
          </>
        );
      case "Developing Speaker":
        return (
          <>
            <p>You have a growing ability in public speaking but may struggle with anxiety, voice control, articulation, or body language. You might feel uncomfortable in front of audiences, rush through speeches, or lack expressiveness.</p>
            <h5>What we will do in this course to enhance your skills:</h5>
            <ul>
              <li>Overcoming fear techniques to manage anxiety and build confidence.</li>
              <li>Vocal projection training to ensure your voice reaches every listener clearly.</li>
              <li>Structured speech planning so you never feel lost or rushed in your delivery.</li>
              <li>Body language mastery to appear more comfortable and engaging.</li>
            </ul>
          </>
        );
      case "Beginner Speaker":
        return (
          <>
            <p>You may avoid public speaking due to anxiety or lack of experience. You likely struggle with nervousness, articulation, voice projection, and body language. You might feel that your words don't have impact, or people struggle to hear and understand you. Don't worry you're at the right place 😊</p>
            <h5>What we will do in this course to enhance your skills:</h5>
            <ul>
              <li>Building confidence from the ground up through small, achievable speaking exercises.</li>
              <li>Step-by-step articulation training to make your speech clearer and easier to understand.</li>
              <li>Body relaxation techniques to help eliminate stiffness and nervous gestures.</li>
              <li>Progressive exposure to speaking situations to help you gain comfort over time.</li>
            </ul>
          </>
        );
      default:
        return <p>Thank you for completing the assessment. Your results are being analyzed.</p>;
    }
  };

  // Get current question from the current section
  const currentSectionData = quizSections[currentSection];
  const currentQuestionData = currentSectionData.questions[currentQuestion];
  const currentQuestionId = currentQuestionData.id;
  const isFirstQuestion = currentSection === 0 && currentQuestion === 0;
  const isLastQuestion = currentSection === quizSections.length - 1 && currentQuestion === quizSections[quizSections.length - 1].questions.length - 1;

  // Component for the Question
  const QuestionItem = () => (
    <div className="question-container">
      <h3 className="mb-4 question-title">{currentQuestionData.text}</h3>

      {currentQuestionData.type === "textarea" ? (
        <FormGroup>
          <Input
            key={`answer-${currentQuestionId}`}
            type="textarea"
            rows="4"
            placeholder="Type your answer here..."
            onChange={(e) => handleAnswerSelect(currentQuestionId, e.target.value)}
            value={answers[currentQuestionId] || ""}
          />
        </FormGroup>
      ) : currentQuestionData.type === "select" ? (
        <FormGroup>
          <Input
            type="select"
            onChange={(e) => handleAnswerSelect(currentQuestionId, parseInt(e.target.value))}
            value={answers[currentQuestionId] || ""}
            className="animated-select"
          >
            <option value="">Select your answer</option>
            {currentQuestionData.options.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.text}
              </option>
            ))}
          </Input>
        </FormGroup>
      ) : (
        <FormGroup tag="fieldset">
          {currentQuestionData.options.map((option, idx) => (
            <div
              key={idx}
              className={`option-card ${answers[currentQuestionId] === option.value ? 'selected' : ''}`}
              onClick={() => handleAnswerSelect(currentQuestionId, option.value)}
            >
              <Input
                type="radio"
                name={`question-${currentQuestionId}`}
                checked={answers[currentQuestionId] === option.value}
                onChange={() => { }}
                className="option-radio"
              />
              <span className="option-text">{option.text}</span>
            </div>
          ))}
        </FormGroup>
      )}
    </div>
  );

  return (
    <div className="quiz-fullscreen">
      <AppNavbar />
      <div className="quiz-banner"></div>
      {startQuiz == false ?
        <div style={{ display: "grid", placeItems: "center", marginTop: "200px" }}>
          <div className="quiz-question-card" style={{ textAlign: "center" }}>
            <h3 className="mb-4 question-title">Welcome to the elevate quiz</h3>
            <img src={require("../assets/img/quiz.png")} alt="Quiz" className="quiz-image" height="100px" />
            <p className="mb-4 question-title" style={{ marginTop: "20px" }}>
              This quiz aims to assess your public speaking
              levels based on confidence, voice projection, body language and articulation. At the
              end of this quiz you will be given a public speaking score as well as what it means to your
              public speaking career and we can provide you with skills in order to improve your score. You will
              also receive a 10% discount once you complete the survey in your email address.
            </p>
            <Button
              className="quiz-cta-button"
              size="lg"
              onClick={handleStartQuiz}
              style={{
                backgroundColor: "#000080",
                borderColor: "#000080",
                padding: "12px 30px",
                fontSize: "18px",
                fontWeight: "600",
                borderRadius: "8px",
                boxShadow: "0 4px 14px rgba(0, 0, 128, 0.25)",
                transition: "all 0.3s ease"
              }}
            >
              <i className="fa fa-check-circle mr-2"></i>
              Start quiz
            </Button>
          </div>
        </div> :
        <div>
          <div className="quiz-main-container">
            <div className="quiz-sidebar">
              <div className="quiz-progress-info">
                <h4>{currentSectionData.title}</h4>
                <p>{currentSectionData.description}</p>
                <div className="progress-container">
                  <Progress
                    value={progress}
                    className="mb-2"
                    style={{ height: "8px" }}
                    color="primary" />
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Question {quizSections.slice(0, currentSection).reduce((acc, section) => acc + section.questions.length, 0) + currentQuestion + 1}</span>
                    <span>of {totalQuestions}</span>
                  </div>
                </div>

                <div className="section-progress">
                  {quizSections.map((section, idx) => (
                    <div
                      key={idx}
                      className={`section-indicator ${idx === currentSection ? 'active' : idx < currentSection ? 'completed' : ''}`}
                      title={section.title}
                    >
                      <span className="section-number">{idx + 1}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="quiz-content">
              <div className="quiz-question-card">
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={`${currentSection}-${currentQuestion}`}
                    timeout={300}
                    classNames={`slide-${animationDirection}`}
                  >
                    <QuestionItem />
                  </CSSTransition>
                </SwitchTransition>

                <div className="navigation-buttons d-flex justify-content-between mt-4">
                  <Button
                    color="secondary"
                    onClick={prevQuestion}
                    disabled={isFirstQuestion}
                    className="btn-prev"
                  >
                    <i className="fas fa-arrow-left mr-2"></i> Previous
                  </Button>
                  <Button
                    color="primary"
                    onClick={nextQuestion}
                    disabled={!answers[currentQuestionId]}
                    className="btn-next"
                  >
                    {isLastQuestion ? "Submit" : "Next"} {!isLastQuestion && <i className="fas fa-arrow-right ml-2"></i>}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }


      <Modal
        isOpen={isResultModalOpen}
        toggle={toggleResultModal}
        size="lg"
        centered
        className="results-modal"
      >
        <ModalHeader toggle={toggleResultModal} className="bg-primary text-white">
          Your Public Speaking Assessment Results
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="text-center py-5">
              <div className="loading-animation">
                <Spinner color="primary" />
              </div>
              <p className="mt-3">Analyzing your responses...</p>
            </div>
          ) : (
            <div className="results-content">
              <div className="text-center mb-4 results-score">
                {/*<h3>Your Score: {score}/100</h3>*/}
                {score >= 85 ? <img src={require("../assets/img/MasterLady.png")} alt="Score" className="score-image" height="300px" /> :
                  score >= 70 ? <img src={require("../assets/img/Strong.png")} alt="Score" className="score-image" height="300px" /> :
                    score >= 50 ? <img src={require("../assets/img/DevelopingSpeaker.png")} alt="Score" className="score-image" height="300px" /> :
                      <img src={require("../assets/img/Beginner.png")} alt="Score" className="score-image" height="300px" />}
                <div className="my-3">
                  <Progress
                    value={score}
                    color={
                      score >= 85 ? "success" :
                        score >= 70 ? "info" :
                          score >= 50 ? "warning" : "danger"
                    }
                    style={{ height: "20px" }}
                    className="score-progress"
                  />
                </div>
                <h4 className="result-category mt-3">
                  {score >= 85 ? "🌟" :
                    score >= 70 ? "📢" :
                      score >= 50 ? "🏗️" : "🌱"} {category}
                </h4>
              </div>

              <div className="result-description">
                {getResultDescription()}
              </div>

              <div className="next-steps mt-4 p-3">
                <h5>Next Steps in the Elevate Africa Public Speaking Program:</h5>
                <ul>
                  <li>Personalized training based on your score breakdown</li>
                  <li>Interactive exercises to boost confidence and improve technical skills</li>
                  <li>Live practice opportunities to refine your skills in a real-world setting</li>
                  <li>Expert feedback and coaching to accelerate your growth</li>
                </ul>
                <p className="mt-3 mb-0 text-center font-weight-bold">
                  🎤 Your voice has power—let's help you unlock it! 🚀
                </p>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleResultModal}>Close</Button>
          <Button
            color="primary"
            onClick={() => navigate("/courses")}
            className="pulse-button"
          >
            Explore Our Courses
          </Button>
        </ModalFooter>
      </Modal>

      <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
        <FooterBlack />
      </div>
    </div>
  );
}

export default SpeakingAssessmentQuiz;