import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import Headroom from "headroom.js";
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { getAuth } from "firebase/auth";

function AppNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        setIsAdmin(idTokenResult.claims.admin || false);
      }
      setLoading(false);
    });

    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, [auth]);
  const handleCloseNav = () => {
    document.documentElement.classList.remove("nav-open");
  };
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand id="navbar-brand" to="/home" tag={Link}>
            ELEVATE AFRICA ACADEMY
          </NavbarBrand>
          <button
            className="navbar-toggler navbar-toggler-right"
            id="navbarSupportedContent3"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(true);
            }}
          >
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbarSupportedContent3">
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={() => handleCloseNav()} to="/home" tag={Link}>
                  HOME
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => handleCloseNav()}
                  to="/about-us"
                  tag={Link}
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => handleCloseNav()}
                  to="/public-speaking"
                  tag={Link}
                >
                  PUBLIC SPEAKING
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => handleCloseNav()}
                  to="/voice-over"
                  tag={Link}
                >
                  VOICE OVER
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => handleCloseNav()}
                  to="/alumni"
                  tag={Link}
                >
                  ALUMNI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => handleCloseNav()} to="/form" tag={Link}>
                  Surveys
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => handleCloseNav()}
                  to="/contact-us"
                  tag={Link}
                >
                  CONTACT US
                </NavLink>
              </NavItem>
              {isAdmin && !loading && (
                <NavItem>
                  <NavLink
                    onClick={() => handleCloseNav()}
                    to="/admin-home"
                    tag={Link}
                  >
                    ADMIN
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <Button
                  color="info"
                  onClick={() => {
                    navigate("/book-now");
                    handleCloseNav();
                  }}
                  type="button"
                  className="learn-more-btn"
                >
                  BOOK NOW
                </Button>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AppNavbar;
