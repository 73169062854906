import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import corporateclients from "assets/img/Untitled design(2).svg";
import InstaNewsSection from "./LandingPageSections/InstaNewsSection";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import TeamDisplay from "views/TeamDisplay";
import Testimonials from "components/Testimonials";
import { getProducts } from "../service/products";
import HeaderWithCarousel from "components/Headers/HeaderWithCarousel";
import ScrollingFlags from "./LandingPageSections/Flag";
import QuizButton from "components/QuizButton/QuizButton"; // Import the Quiz Button component
import "../Styling/App.css";
import "../Styling/LandingPage.css";

function LandingPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [teamLoading, setTeamLoading] = useState(true);
  const [newsLoading, setNewsLoading] = useState(true);
  const [media, setMedia] = useState([]);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    fetchProducts();
    fetchInstagramMedia();
    
    setTimeout(() => {
      setTeamLoading(false);
    }, 1500);

    return () => {
      document.body.classList.remove("landing-page");
    };
  }, []);

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.success && response.data.length > 0) {
      let data = [...response.data].sort((a, b) => a.p_order - b.p_order);
      setProducts([...data]);
      setProductsLoading(false);
    }
  };

  const fetchInstagramMedia = async () => {
    try {
      const response = await fetch(
        `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=YOUR_ACCESS_TOKEN`
      );
      const data = await response.json();
      setMedia(data.data);
      setNewsLoading(false);
    } catch (error) {
      console.error("Error fetching Instagram media:", error);
      setNewsLoading(false);
    }
  };

  return (
    <>
      <AppNavbar />
      <HeaderWithCarousel />

      {/* Pop-up Modal for Site Under Construction */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>🚧 Site Under Construction 🚧</ModalHeader>
        <ModalBody className="text-center">
          <p>We are currently working on improving the website. Stay tuned for updates!</p>
          <Button backgroundColor="#000080" onClick={toggleModal}>Close</Button>
        </ModalBody>
      </Modal>

      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container className="custom-container">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title" style={{ fontWeight: "bold", fontSize: "36px" }}>
                  WELCOME TO ELEVATE AFRICA ACADEMY
                </h2>
                <p className="card-description" style={{ fontSize: "18px" }}>
                  Our comprehensive courses unlock your potential, giving you the tools to 
                  captivate audiences and master the art of communication.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Quiz Button Section - Add this right after the welcome section */}
        <div className="section" style={{ backgroundColor: "#f5f6fa", padding: "30px 0" }}>
          <Container>
            <QuizButton />
          </Container>
        </div>

        {/* Experienced Team Section */}
        <div className="section text-center landing-section">
          <div className="section" style={{ backgroundColor: "#f5f6fa", paddingBottom: "40px" }}>
            <h2 className="title" style={{ fontWeight: "bold", fontSize: "36px", marginBottom: "40px" }}>
              EXPERIENCED TEAM
            </h2>
            {teamLoading ? <Spinner color="primary" /> : <TeamDisplay />}
          </div>
        </div>

        {/* What to Expect Section */}
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ fontWeight: "bold", fontSize: "36px" }}>WHAT TO EXPECT</h2>
              </Col>
            </Row>

            {productsLoading ? (
              <Spinner color="primary" />
            ) : (
              products.map((product, index) => (
                <Row key={index} style={{ backgroundColor: "#8A95A5", marginBottom: "20px", borderRadius: "8px" }}>
                  <Col className="mr-auto" md="6">
                    <iframe
                      style={{ width: "100%", height: "250px", border: "none" }}
                      src={product.p_video_url}
                      title={product.p_title}
                      allowFullScreen
                    ></iframe>
                  </Col>
                  <Col className="mr-auto" md="5">
                    <Card className="card-plain" style={{ border: "none" }}>
                      <CardBody>
                        <h3 className="card-category">{product.p_title} - R{product.p_price}</h3>
                        <ul>
                          {product.p_description.map((desc, i) => (
                            <li key={i}>{desc}</li>
                          ))}
                        </ul>
                        {product.p_buttons.map((btn, i) => (
                          <Button key={i} color="primary" onClick={() => navigate(btn.url)}>{btn.title}</Button>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ))
            )}
          </Container>
        </div>

        {/* Corporate Clients */}
        <Container className="text-center">
          <h2 style={{ fontWeight: "bold", fontSize: "36px" }}>CORPORATE CLIENTS</h2>
          <img src={corporateclients} alt="Corporate Clients" style={{ width: "100%", maxWidth: "1200px" }} />
        </Container>

        {/* News & Updates */}
        {media.length > 0 && (
          <Container>
            <h2 className="title" style={{ fontSize: "36px" }}>NEWS & UPDATES</h2>
            {newsLoading ? <Spinner color="primary" /> : <InstaNewsSection mediaArray={media} />}
          </Container>
        )}

        <Testimonials />
        <FooterBlack />
      </div>
    </>
  );
}

export default LandingPage;