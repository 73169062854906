import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";

function BrandSAPage() {
  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <div
        className="wrapper"
        style={{ backgroundColor: "#eaf2f7", padding: "20px" }}
      >
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
        >
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2
                className="title"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                Brands South Africa
              </h2>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md="6" style={{ textAlign: "left" }}>
              <p
                style={{ fontWeight: "400", color: "black", lineHeight: "1.6" }}
              >
                Elevate Africa Academy hosts an annual Public Speaking
                competition at the University of Pretoria, which was launched in
                2022. Public speaking, ranked as the fourth most in-demand skill
                globally, is the focus of this competition, aiming to cultivate
                and encourage this crucial ability among young people. The event
                is supported by the Elevate Africa @UP society, offering
                students the opportunity to become members and participate in
                various public speaking-focused events, games, masterclasses,
                and activities. This collaboration directly aligns with the
                vision of Elevate Africa Academy—to amplify African voices.
              </p>
              <p
                style={{ fontWeight: "400", color: "black", lineHeight: "1.6" }}
              >
                The competition is a platform where young people showcase their
                potential and contributions toward the advancement of our
                country and continent. Each year, the event attracts around 100
                applicants, leading to a grand finale where six speakers
                compete, and one emerges as the Top Public Speaker at the
                University of Pretoria. In the inaugural year of 2022, Brand
                South Africa played a significant role by sending a
                representative who served as a judge. Brand SA’s involvement
                didn’t end there; they also committed to incorporating more
                youth voices in their recognition and celebration of South
                African talent, which aligns perfectly with their ethos.
              </p>
              <p
                style={{ fontWeight: "400", color: "black", lineHeight: "1.6" }}
              >
                This annual event has quickly become a cornerstone for fostering
                essential skills such as communication, leadership, and
                confidence among the youth. Elevate Africa Academy, through this
                competition, is investing in the future of African leadership by
                equipping young people with the tools they need to articulate
                their visions and inspire positive change across the continent.
                The platform not only prepares students for public speaking but
                also empowers them to become influential voices in their
                communities and beyond.
              </p>
            </Col>
            <Col md="6">
              <img
                src={require("assets/img/brands-south-africa.png")}
                alt="Brand South Africa Partnership"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  maxWidth: "400px",
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <FooterBlack />
    </>
  );
}

export default BrandSAPage;
